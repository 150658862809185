<template>
  <div class="container-fluid" id="CreateSubCategoryAdmin">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Create new Subcategory</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">Please provide subcategory name</div>
          <div class="card-body">
            <div class="col-md-8">
              <h3></h3>
              <!-- Create form -->
              <form @submit.prevent="createCategory">
                <div class="form-group">
                  <label for="name">
                    Select Category
                    <span class="text-danger">*</span>
                  </label>
                  <select v-model="facultyUUID" :class="{ 'is-invalid': $v.name.$error, 'is-valid': !$v.name.$invalid }" class="form-control">
                    <option>Select ...</option>
                    <option v-for="cat in categories" :key="cat.uuid" :value="cat.uuid">{{ cat.name }}</option>
                  </select>
                  <div class="invalid-feedback">
                    <span v-if="!$v.name.required">Category is required.</span>
                  </div>
                </div>

                <div class="form-group">
                  <label for="name">
                    Subcategory name
                    <span class="text-danger">*</span>
                  </label>
                  <input type="text" v-model="name" class="form-control" :class="{ 'is-invalid': $v.name.$error, 'is-valid': !$v.name.$invalid }" />
                  <div class="invalid-feedback">
                    <span v-if="!$v.name.required">Subcategory name is required.</span>
                    <span v-if="!$v.name.minLength">Subcategory name must be at least {{ $v.name.$params.minLength.min }} letters.</span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="name">Subcategory description</label>
                  <textarea v-model="description" class="form-control" rows="3" />
                  <!-- <div class="invalid-feedback">
                          <span v-if="!$v.description.minLength">Supervisor description must be at least {{ $v.name.$params.minLength.min }} letters.</span>
                      </div>-->
                </div>
                <button class="btn btn-danger btn-block">Create new Subcategory</button>
              </form>
              <!-- / Create form -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "CreateSubCategoryAdmin",

  data() {
    return {
      categories: [],
      facultyUUID: "",
      name: "",
      description: "",
    };
  },

  mounted() {
    let loading = this.$loading.show();
    axios.get("/admin/categories").then((res) => {
      this.categories = res.data.categories;
      loading.hide();
    });
  },

  methods: {
    createCategory() {
      let loading = this.$loading.show();
      this.$v.$touch();
      if (this.$v.$invalid) {
        loading.hide();
        return;
      }
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("description", this.description);
      formData.append("topCategoryUUID", this.facultyUUID);
      axios.post("admin/sub-category/create", formData).then((res) => {
        if (res.data.status == "success") {
          //res.data.category.subCategories = [];
          //this.categories.push(res.data.category);
          this.$swal("", `${this.name} created successfully`, "success");
          this.$router.push({ name: "SubCategoriesAdmin" });
        } else {
          this.$swal("", res.data.message, "error");
        }
        loading.hide();
      });
    },
  },

  computed: {
    client() {
      return this.$store.state.client;
    },
  },

  created() {
    //this.$store.commit("SET_LAYOUT", "agent-layout");
  },

  validations: {
    name: { required, minLength: minLength(2) },
    facultyUUID: { required },
    //description: { minLength: minLength(2) }
  },
};
</script>

