<template>
  <div>
    <span v-if="status == 'open'" class="badge badge-danger">{{ name }}</span>
    <span v-else-if="status == 'in_progress'" class="badge badge-primary">{{ name }}</span>
    <span v-else-if="status == 'closed'" class="badge badge-success">{{ name }}</span>
    <span v-else-if="status == 'escalated'" class="badge badge-warning">{{ name }}</span>
    <span v-else-if="status == 'reopened'" class="badge badge-info">{{ name }}</span>
  </div>
</template>

<script>
export default {
  props: { status: String, name: String }
}
</script>

<style></style>
