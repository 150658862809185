<template>
  <b-nav-item-dropdown right>
    <template #button-content>
      <span class="myNotify">
        <span class="myNotifyBadge">{{ notifications.length }}</span>
        <span></span>
      </span>
      <b-icon-bell></b-icon-bell>
    </template>
    <!--  -->
    <div class="list-group list-group-flush notificationWrapper" v-if="notifications.length > 0">
      <a href="#" v-for="ntf in notifications" :key="ntf.id" class="list-group-item list-group-item-action">
        <div class="d-flex w-100 justify-content-between">
          <p class="mb-1" style="font-size: .85rem" @click="LinkTo(ntf.ref)">{{ ntf.content }}</p>
          <small class="text-muted">
            <button class="btn btn-outline-secondary btn-xs" @click="Delete(ntf.id)">
              <b-icon-x></b-icon-x>
            </button>
          </small>
        </div>
        <!-- <p class="mb-1">Some placeholder content in a paragraph.</p> -->
        <small class="text-muted" style="font-size: .6rem">{{ ntf.createdAt | formatDateTime }}</small>
      </a>
    </div>
    <!--  -->
    <div class="notificationEmpty" v-if="notifications.length < 1">You have no notification yet.</div>
  </b-nav-item-dropdown>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Notifications',

  data() {
    return {
      notifications: []
    }
  },

  mounted() {
    axios.get('/notifications').then(res => {
      if (res.data.status == 'success') {
        this.notifications = res.data.notifications
      }
    })
  },

  methods: {
    LinkTo(ref) {
      this.$router.push(`/${this.authUser.Role}/ticket/${ref}`)
    },

    Delete(id) {
      axios.delete(`/notifications/${id}`).then(res => {
        if (res.data.status == 'success') {
          this.notifications = this.notifications.filter(n => n.id !== id)
        }
      })
    }
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    }
  }
}
</script>

<style scoped>
.notificationWrapper {
  width: 400px;
}
.notificationEmpty {
  width: 400px;
  padding: 1rem 0;
  text-align: center;
  font-size: 0.8rem;
  color: #a7a8ad;
}
/* Notification */
.myNotify {
  display: flex;
  flex-direction: column;
}
.myNotifyBadge {
  color: #fff;
  z-index: 1000;
  padding: 1px 6px;
  font-size: 0.6rem;
  border-radius: 10px;
  background-color: #d11010;
  margin: -16px 0px 0px 0px !important;
  /* max-width: 40px; */
  width: auto;
  height: auto;
  text-align: center;
}
</style>
