import auth from '../helper/auth'
import TicketDetailsAgent from '../views/agent/TicketDetailsAgent.vue'
import ChangePasswordAgent from '../views/ChangePassword.vue'
import TicketsAgent from '../views/agent/TicketsAgent.vue'
import ProfileAgent from '../views/Profile.vue'
import AgentLayout from '../layouts/Agent.vue'
import FAQAgent from '../views/agent/FAQAgent.vue'
import KnowledgeBaseAgent from '../views/agent/KnowledgeBaseAgent.vue'

const agentRoutes = [
  {
    path: '/agent',
    component: AgentLayout,
    beforeEnter: auth.ifAuthenticated,
    children: [
      {
        path: '/',
        name: 'HomeAgent',
        component: TicketsAgent
      },
      {
        path: 'tickets',
        name: 'TicketsAgent',
        component: TicketsAgent
      },
      {
        path: 'tickets/:status', // status is sticket state e.g. open, closed, escalated
        name: 'TicketsAgent2',
        component: TicketsAgent
      },
      {
        path: 'profile',
        name: 'ProfileAgent',
        component: ProfileAgent
      },

      {
        path: 'ticket/:ref',
        name: 'TicketDetailsAgent',
        component: TicketDetailsAgent
      },
      {
        path: 'change-password',
        name: 'ChangePasswordAgent',
        component: ChangePasswordAgent
      },
      {
        path: 'faqs',
        name: 'FAQAgent',
        component: FAQAgent
      },
      {
        path: 'knowledge-base',
        name: 'KnowledgeBaseAgent',
        component: KnowledgeBaseAgent
      }
    ]
  }
]

export default agentRoutes
