import auth from '../helper/auth'
import TicketDetailsSupervisor from '../views/supervisor/TicketDetailsSupervisor.vue'
import AgentsListSupervisor from '../views/supervisor/AgentsListSupervisor.vue'
import ChangePasswordSupervisor from '../views/ChangePassword.vue'
import TicketsSupervisor from '../views/supervisor/TicketsSupervisor.vue'
import ProfileSupervisor from '../views/Profile.vue'
import SupervisorLayout from '../layouts/Supervisor.vue'

const supervisorRoutes = [
  {
    path: '/supervisor',
    component: SupervisorLayout,
    beforeEnter: auth.ifAuthenticated,
    children: [
      {
        path: '/',
        name: 'HomeSupervisor',
        component: TicketsSupervisor
      },
      {
        path: 'tickets',
        name: 'TicketsSupervisor',
        component: TicketsSupervisor
      },
      {
        path: 'tickets/:status', // status is sticket state e.g. open, closed, escalated
        name: 'TicketsSupervisor2',
        component: TicketsSupervisor
      },
      {
        path: 'agents',
        name: 'AgentsListSupervisor',
        component: AgentsListSupervisor
      },
      {
        path: 'profile',
        name: 'ProfileSupervisor',
        component: ProfileSupervisor
      },

      {
        path: 'ticket/:ref',
        name: 'TicketDetailsSupervisor',
        component: TicketDetailsSupervisor
      },

      {
        path: 'change-password',
        name: 'ChangePasswordSupervisor',
        component: ChangePasswordSupervisor
      }
    ]
  }
]

export default supervisorRoutes
