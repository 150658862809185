import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    client: {
      shortName: ""
    },
    authUser: {},
    authToken: '',
    webview: false,
  },
  mutations: {
    SET_WEBVIEW(state, data) {
      state.webview = data;
    }
  },
  actions: {
    getWebview({
      commit
    }) {

      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      //console.log(params);
      let s = params.source;
      if (s === "mobile") {
        commit('SET_WEBVIEW', true)
      } else {
        commit('SET_WEBVIEW', false)
      }
    }
  },
  modules: {}
})