<template>
  <div class="container-fluid" id="UserDetailsAdmin">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Account Details</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header"><BackIcon /> User Details</div>
          <div class="card-body">
            <table class="table table-bordered">
              <tr>
                <th>Name</th>
                <td>{{ user.name }}</td>
              </tr>

              <tr>
                <th>Email</th>
                <td>{{ user.email }}</td>
              </tr>
              <tr>
                <th>Phone</th>
                <td>{{ user.phone }}</td>
              </tr>
              <tr>
                <th>Role</th>
                <td>
                  <!-- <span v-if="user.role.slug === 'admin'">Admin</span>
                      <span v-if="user.role.slug === 'agent'">Agent</span>
                      <span v-if="user.role.slug === 'supervisor'">Supervisor</span> -->
                  {{ user.role.name }}
                </td>
              </tr>

              <tr v-if="user.role.slug === 'supervisor'">
                <th>Supervisor</th>
                <td>
                  <ul>
                    <li v-for="(cat, index) in user.categories" :key="cat.uuid">
                      {{ cat.name }}
                      <span @click="unassign(cat.uuid, 'supervisor', index)" class="text-danger" style="font-size: 12px; cursor: pointer"
                        >[unassign]</span
                      >
                    </li>
                  </ul>
                  <router-link :to="{ name: 'AssignSupervisorAdmin', params: { uuid: this.$route.params.uuid } }">Assign to Supervisor</router-link>
                </td>
              </tr>
              <tr v-if="user.role.slug === 'agent'">
                <th>Agent</th>
                <td>
                  <ul v-show="user.subCategories.length > 0">
                    <li v-for="(cat, index) in user.subCategories" :key="cat.uuid">
                      {{ cat.name }}
                      <span @click="unassign(cat.uuid, 'agent', index)" class="text-danger" style="font-size: 12px; cursor: pointer">[unassign]</span>
                      <span style="font-size: 11px; display: block; margin-bottom: 7px">{{ cat.category.name }}</span>
                    </li>
                  </ul>
                  <router-link :to="{ name: 'AssignAgentAdmin', params: { uuid: this.$route.params.uuid } }">Assign to Agent</router-link>
                </td>
              </tr>
              <tr>
                <th>Created Date/Time</th>
                <td>{{ user.createdAt | formatDateTime }} <br /></td>
              </tr>
              <tr v-if="user.deletedAt != null">
                <th>Deleted Date/Time</th>
                <td class="text-danger">{{ user.deletedAt | formatDateTime }} <br /></td>
              </tr>
            </table>

            <span v-if="authUserRole == 'admin'">
              <!--  <div v-if="user.role.slug != 'user'"> -->
              <div v-if="user.deletedAt == null">
                <router-link :to="{ name: 'EditUserAdmin', params: { uuid: this.$route.params.uuid } }" class="btn btn-outline-secondary btn-sm"
                  >Edit</router-link
                >
                <DeActivateUser :user="user" />
                <ForcePasswordReset :user="user" />
              </div>
              <!--  -->
              <div v-if="user.deletedAt != null">
                <ReActivateUser :user="user" />
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BackIcon from '@/components/BackIcon.vue'
import DeActivateUser from '@/components/DeActivateUser.vue'
import ReActivateUser from '@/components/ReActivateUser.vue'
import ForcePasswordReset from '@/components/ForcePasswordReset.vue'

export default {
  name: 'UserDetailsAdmin',

  components: {
    BackIcon,
    ReActivateUser,
    DeActivateUser,
    ForcePasswordReset
  },

  data() {
    return {
      user: { role: {} }
    }
  },

  mounted() {
    let loading = this.$loading.show()
    axios.get(`/${this.authUserRole}/user/${this.$route.params.uuid}`).then(res => {
      this.user = res.data.user
      loading.hide()
    })
  },

  methods: {
    unassign(uuid, role, index) {
      this.$swal
        .fire({
          text: 'Please confirm un-assigning user',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, confirmed'
        })
        .then(result => {
          if (result.value) {
            /* actural action */
            let loading = this.$loading.show()
            let formData = new FormData()
            formData.append('userUUID', this.$route.params.uuid)
            formData.append('categoryUUID', uuid)
            axios.post(`/admin/assign-un${role}`, formData).then(res => {
              //console.log(res.data);
              if (res.data.status == 'success') {
                if (role === 'agent') {
                  this.user.subCategories.splice(index, 1)
                } else if (role === 'supervisor') {
                  this.user.categories.splice(index, 1)
                }
                this.$swal('', res.data.message, 'success')
              } else {
                this.$swal('', res.data.message, 'error')
              }

              loading.hide()
            })
            /* end of actural action */
          }
        })
    }
  },

  computed: {
    client() {
      return this.$store.state.client
    },

    authUserRole() {
      return this.$store.state.authUser.Role
    }
  },

  created() {
    //this.$store.commit("SET_LAYOUT", "agent-layout");
  }
}
</script>
