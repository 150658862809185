<template>
  <div class="nelfund-top ">
    <div class="logo">
      <img alt="NelFund Logo" src="../assets/nelfund-logo.png" width="130" />
    </div>
    <div class="name">
      <!--  <div class="university-name">NIGERIAN EDUCATION LOAN FUND</div> -->
      <div class="platform-name">Support Ticketing Platform</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NelFundTop'
}
</script>

<style>
.nelfund-top {
  width: 100vw;
  height: auto;
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
  background-color: #dfe1e5;
}

.nelfund-top .logo {
  margin-left: 2rem;
  /*  img {
      width: 5rem;
    } */
}

.nelfund-top .name {
  margin-left: 1rem;
  color: #212121;
}

.nelfund-top .name .university-name {
  font-weight: bold;
  font-size: 1.3rem;
}
.nelfund-top .name .platform-name {
  font-size: 1.1rem;
}

@media (max-width: 576px) {
  .nelfund-top .logo {
    margin-left: 0.7rem;
  }
  .nelfund-top .logo img {
    width: 3rem;
    height: auto;
  }

  .nelfund-top .name {
    margin-left: 0.7rem;
  }
  .nelfund-top .name .university-name {
    font-size: 0.9rem;
  }
  .nelfund-top .name .platform-name {
    font-size: 0.7rem;
  }
}
</style>
