<template>
  <vue-good-table
    :columns="columns"
    :rows="tickets"
    :line-numbers="true"
    :search-options="{
      enabled: true
    }"
    :pagination-options="{
      enabled: true,
      perPage: 100
    }"
    :sort-options="{
      enabled: true,
      multipleColumns: true
    }"
    styleClass="vgt-table condensed myGoodTable"
  >
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'ref'">
        <router-link :to="{ name: routeName, params: { ref: props.row.ref } }">
          <b>{{ props.row.ref }}</b>
        </router-link>
      </span>
      <span v-if="props.column.field == 'status'">
        <span v-if="props.row.status == 'Open'" class="badge badge-danger">{{ props.row.status }} </span>
        <span v-if="props.row.status == 'Closed'" class="badge badge-success">{{ props.row.status }} </span>
        <span v-if="props.row.status == 'Escalated'" class="badge badge-warning">{{ props.row.status }} </span>
        <span v-if="props.row.status == 'Reopened'" class="badge badge-info">{{ props.row.status }} </span>
         <span v-if="props.row.status == 'InProgress'" class="badge badge-primary">{{ props.row.status }} </span>
      </span>
      <span v-if="props.column.field == 'category'"> {{ props.row.category }} </span>
      <span v-if="props.column.field == 'subcategory'">{{ props.row.subcategory }}</span>
      <span v-if="props.column.field == 'matric'">{{ props.row.matric }}</span>
      <span v-if="props.column.field == 'createdBy'">{{ props.row.createdBy }}</span>
      <span v-if="props.column.field == 'assignedTo'">{{ props.row.assignedTo }}</span>
      <span v-if="props.column.field == 'createdAt'">{{ props.row.createdAt | formatDateTime }}</span>
      <span v-if="props.column.field == 'currentStatusDate'">{{ props.row.currentStatusDate }}</span>
    </template>
  </vue-good-table>
</template>

<script>
export default {
  name: 'ListPublic',

  props: {
    tickets: { type: Array },
    routeName: String
  },

  data() {
    return {
      columns: [
        {
          label: 'ReferenceID',
          field: 'ref'
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Category',
          field: 'category'
        },
        {
          label: 'Subcategory',
          field: 'subcategory'
        },
        {
          label: 'Matric',
          field: 'matric'
        },
        {
          label: 'AssignedTo',
          field: 'assignedTo'
        },
        {
          label: 'CreatedBy',
          field: 'createdBy'
        },
        {
          label: 'CreatedAt',
          field: 'createdAt'
        }
        /*  {
          label: 'Status Date',
          field: 'currentStatusDate'
        } */
      ]
    }
  }
}
</script>

<style>
.myGoodTable {
  font-size: 0.8rem !important;
}
</style>
