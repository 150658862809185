<template>
  <div id="DashboardOfficeScreen">
    <div class="row">
      <div class="col-xl-12" style="text-align: center">
        <h1 class="mb-5" style="font-size: 40px">{{ categoryName }}</h1>
      </div>
    </div>
    <div class="row">
      <!-- Raised -->
      <div class="col-xl-3 col-md-6">
        <div class="card bg-primary text-white mb-4">
          <div class="card-body">
            <h1 class="mt-2 mb-2 text-white" v-show="infoBox.raised > 0">{{ infoBox.raised | thousandSeparator }}</h1>
            <h1 class="mt-2 mb-2 text-white" v-show="infoBox.raised < 1">0</h1>
            Raised
            <span class="periodLabel">{{ periodLabel }}</span>
          </div>
          <div class="card-footer d-flex align-items-center justify-content-between">
            <router-link :to="{ name: 'TicketsOfficeScreen', query: { status: 'all', uuid: uuid } }" class="small text-white stretched-link">View Details</router-link>
            <div class="small text-white">
              <i class="fas fa-angle-right"></i>
            </div>
          </div>
        </div>
      </div>

      <!-- Open -->
      <div class="col-xl-3 col-md-6">
        <div class="card bg-danger text-white mb-4">
          <div class="card-body">
            <h1 class="mt-2 mb-2 text-white" v-show="infoBox.open > 0">{{ infoBox.open | thousandSeparator }}</h1>
            <h1 class="mt-2 mb-2 text-white" v-show="infoBox.open < 1">0</h1>
            Open
            <span class="periodLabel">{{ periodLabel }}</span>
          </div>
          <div class="card-footer d-flex align-items-center justify-content-between">
            <router-link :to="{ name: 'TicketsOfficeScreen', query: { status: 'open', uuid: uuid } }" class="small text-white stretched-link">View Details</router-link>
            <div class="small text-white">
              <i class="fas fa-angle-right"></i>
            </div>
          </div>
        </div>
      </div>

      <!-- Escalated -->
      <div class="col-xl-3 col-md-6">
        <div class="card bg-warning text-white mb-4">
          <div class="card-body">
            <h1 class="mt-2 mb-2 text-white" v-show="infoBox.escalated > 0">{{ infoBox.escalated | thousandSeparator }}</h1>
            <h1 class="mt-2 mb-2 text-white" v-show="infoBox.escalated < 1">0</h1>
            Escalated
            <span class="periodLabel">{{ periodLabel }}</span>
          </div>
          <div class="card-footer d-flex align-items-center justify-content-between">
            <router-link :to="{ name: 'TicketsOfficeScreen', query: { status: 'escalated', uuid: uuid } }" class="small text-white stretched-link">View Details</router-link>
            <div class="small text-white">
              <i class="fas fa-angle-right"></i>
            </div>
          </div>
        </div>
      </div>

      <!-- Closed -->
      <div class="col-xl-3 col-md-6">
        <div class="card bg-success text-white mb-4">
          <div class="card-body">
            <h1 class="mt-2 mb-2 text-white" v-show="infoBox.closed > 0">{{ infoBox.closed | thousandSeparator }}</h1>
            <h1 class="mt-2 mb-2 text-white" v-show="infoBox.closed < 1">0</h1>
            Closed
            <span class="periodLabel">{{ periodLabel }}</span>
          </div>
          <div class="card-footer d-flex align-items-center justify-content-between">
            <router-link :to="{ name: 'TicketsOfficeScreen', query: { status: 'closed', uuid: uuid } }" class="small text-white stretched-link">View Details</router-link>
            <div class="small text-white">
              <i class="fas fa-angle-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DashboardOfficeScreen",
  data() {
    return {
      infoBox: {},
      periodLabel: "",
      categoryName: "",
    };
  },

  mounted() {
    axios.get(`/office-screen/dashboard?q=year&uuid=${this.uuid}`).then((res) => {
      this.infoBox = res.data.infoBox;
      this.periodLabel = res.data.periodLabel;
      this.categoryName = res.data.category.name;
    });
  },

  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },

  computed: {
    client() {
      return this.$store.state.client;
    },

    uuid() {
      let uuid = "";
      if (this.$route.query != {}) {
        uuid = this.$route.query.uuid;
      }
      return uuid;
    },
  },
};
</script>

<style scoped>
.periodLabel {
  font-size: 12px;
  color: #f9f9f9;
}
</style>