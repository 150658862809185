<template>
  <div class="PasswordReset">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-sm-12 col-12">
          <div class="card mb-3">
            <div class="card-header"><BackIcon /><b-icon-key v-if="!$routerHistory.hasPrevious()"></b-icon-key> Reset Password</div>
            <div class="card-body">
              <p>Please enter your email</p>

              <form @submit.prevent="resetPassword">
                <div class="form-group mb-3">
                  <label for="">Email or matric</label>
                  <input v-model="username" type="text" class="form-control form-control-lg" placeholder="email or matric" :class="{ 'is-invalid': $v.username.$error, 'is-valid': !$v.username.$invalid }" />
                  <div class="invalid-feedback">
                    <span v-if="!$v.username.required">Email or matric is required.</span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <b-spinner v-if="spinner" variant="success" label="Spinning"></b-spinner>
                    <button v-if="!spinner" type="submit" class="btn btn-danger">Send Password Reset Link</button>
                  </div>
                  <!-- /.col -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import BackIcon from "@/components/BackIcon.vue";

export default {
  name: "PasswordReset",
  data() {
    return {
      username: "",
      spinner: false,
    };
  },

  methods: {
    resetPassword() {
      // stop here if form is invalid
      this.spinner = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.spinner = false;
        return;
      }

      let form = new FormData();
      form.append("username", this.username);
      form.append("originURL", window.location.origin);
      axios
        .post("/auth/password-reset", form)
        .then((res) => {
          if (res.data.status == "success") {
            this.$swal("", res.data.message, "success");
          } else {
            this.$swal("", res.data.message, "error");
          }
          this.spinner = false;
        })
        .catch((err) => {
          this.$swal("", "Unable to send request.", "error");
          this.spinner = false;
        });
    },
  },

  validations: {
    username: { required },
  },
  components: { BackIcon },
};
</script>



