var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid",attrs:{"id":"CreateSubCategoryAdmin"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"pageTitle"},[_vm._v(_vm._s(_vm.category.name))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header"},[_c('BackIcon'),_vm._v(" Subcategories ")],1),_c('div',{staticClass:"card-body-"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.subCategories,"line-numbers":true,"search-options":{
              enabled: true
            },"pagination-options":{
              enabled: true,
              perPage: 20
            },"sort-options":{
              enabled: true,
              initialSortBy: [{ field: 'name', type: 'asc' }]
            },"styleClass":"vgt-table condensed myGoodTable"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',[_vm._v(_vm._s(props.row.name))]):_vm._e(),(props.column.field == 'category')?_c('span',[_vm._v(_vm._s(props.row.category))]):_vm._e(),(props.column.field == 'usersTotal')?_c('span',[_vm._v(_vm._s(props.row.usersTotal))]):_vm._e(),(props.column.field == 'description')?_c('span',[_vm._v(_vm._s(props.row.description))]):_vm._e(),(props.column.field == 'usersbutton' && _vm.authUserRole == 'admin')?_c('span',[_c('AssignAgent',{attrs:{"subCategory":props.row}})],1):_vm._e(),(props.column.field == 'edit' && _vm.authUserRole == 'admin')?_c('span',[_c('router-link',{staticClass:"btn btn-xs btn-outline-secondary",attrs:{"to":{ name: 'EditSubCategoryAdmin', params: { uuid: props.row.uuid } },"title":"Update"}},[_vm._v("edit")])],1):_vm._e(),(props.column.field == 'delete' && _vm.authUserRole == 'admin')?_c('span',[_c('button',{staticClass:"btn btn-xs btn-outline-secondary",attrs:{"title":"Delete"},on:{"click":function($event){return _vm.deleteSubCategory(props.row.uuid, props.row.originalIndex)}}},[_vm._v("delete")])]):_vm._e()]}}])},[(_vm.authUserRole == 'admin')?_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('router-link',{staticClass:"btn btn-success btn-sm mr-3",attrs:{"to":{ name: 'CreateSubCategoryAdmin' }}},[_vm._v("Create")])],1):_vm._e()])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }