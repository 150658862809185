import Vue from 'vue'
import VueRouter from 'vue-router'
import VueRouterBackButton from 'vue-router-back-button'

import userRoutes from './user'
import agentRoutes from './agent'
import customRoutes from './custom'
import studentRoutes from './public'
import managerRoutes from './manager'
import supervisorRoutes from './supervisor'

import adminRoutes from './admin'
import officeScreenRoutes from './officeScreen'

Vue.use(VueRouter)

const allRoutes = []

const routes = allRoutes.concat(agentRoutes, customRoutes, supervisorRoutes, userRoutes, officeScreenRoutes, adminRoutes, managerRoutes, studentRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Vue.use(VueRouterBackButton, {
  router
})

export default router
