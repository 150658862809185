<template>
  <tr>
    <td colspan="2" style="border:none">
      <small>
        <table class="table">
          <tr>
            <td style="border:none">
              {{ user.matric }} <br />
              {{ user.name }} <br />
              {{ user.email }} <br />
              {{ user.phone }}
            </td>
            <td style="border:none">
              {{ faculty }} <br />
              {{ programme }} <br />
              {{ studycenter }}
            </td>
          </tr>
        </table>
      </small>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    user: {
      name: String
    },
    faculty: String,
    programme: String,
    studycenter: String
  }
}
</script>

<style></style>
