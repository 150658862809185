<template>
  <div id="Home">
    <div class="row">
      <div class="col-3">
        <div class="card mb-4">
          <div class="card-body" @click="reports('top')">
            <h5 class="card-title text-primary">{{ client.name }}</h5>
            <!-- <p class="card-text"></p> -->
          </div>
          <div class="card-footer">
            <table style="width: 100%">
              <tr>
                <td class="raisedLabel">Raised</td>
                <td class="unresolvedLabel">Unresolved</td>
              </tr>
              <tr>
                <th class="ticketRaised">{{ raised | thousandSeparator }}</th>
                <th class="ticketOpen">{{ unresolved | thousandSeparator }}</th>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-3" v-for="(cat, i) in categories" :key="i">
        <div class="card mb-4" @click="reports(cat.UUID)">
          <div class="card-body">
            <h5 class="card-title text-dark">{{ cat.Name }}</h5>
            <!-- <p class="card-text"></p> -->
          </div>
          <div class="card-footer">
            <table style="width: 100%">
              <tr>
                <td class="raisedLabel">Raised</td>
                <td class="unresolvedLabel">Unresolved</td>
              </tr>
              <tr>
                <th class="ticketRaised">{{ cat.Raised | thousandSeparator }}</th>
                <th class="ticketOpen">{{ cat.Unresolved | thousandSeparator }}</th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import { Chart } from "highcharts-vue";
//import DateRangePicker from "vue2-daterange-picker";
//import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "Home",
  data() {
    return {
      categories: [],
      raised: 0,
      unresolved: 0,
    };
  },

  mounted() {
    axios.get("/office-screen").then((res) => {
      this.raised = res.data.raised;
      this.unresolved = res.data.unresolved;
      this.categories = res.data.categories;
    });
  },

  methods: {
    reports(id) {
      if (id == "top") {
        this.$router.push("/office-screen/dashboard");
      } else {
        this.$router.push(`/office-screen/dashboard?uuid=${id}`);
      }
    },
  },

  computed: {
    client() {
      return this.$store.state.client;
    },
  },
};
</script>

<style scoped>
.periodLabel {
  font-size: 12px;
  color: #f9f9f9;
}
.card {
  cursor: pointer;
}
.card-title {
  margin-bottom: 0;
}
.card-body {
  min-height: 100px;
  /* padding-top: 50px;
  padding-bottom: 50px; */
}
.card-footer {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px;

  background-color: #fff;
  border-top: none;
}
.ticketRaised {
  color: #0061f2;
}
.ticketOpen {
  color: #e81500;
  text-align: right;
}
.raisedLabel,
.unresolvedLabel {
  font-size: 12px;
}
.unresolvedLabel {
  text-align: right;
}
</style>