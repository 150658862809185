<template>
  <div id="TicketsAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Tickets</div>
        <!-- <div class="subPageTitle" v-if="status != 'all'">{{ status }}</div> -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <List :routeName="routeName" />
      </div>
    </div>
  </div>
</template>

<script>
import List from '@/components/List.vue'

export default {
  name: 'TicketsAdmin',

  data() {
    return {}
  },

  methods: {},

  components: {
    List
  },

  computed: {
    authUserRole() {
      return this.$store.state.authUser.Role
    },
    routeName() {
      if (this.$store.state.authUser.Role === 'admin') {
        return 'TicketDetailsAdmin'
      } else {
        return 'TicketDetailsManager'
      }
    }
  }
}
</script>
<style></style>
