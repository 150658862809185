<template>
  <div id="UsersAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">
          <span v-if="role == 'admin'">Administrators</span>
          <span v-if="role == 'supervisor'">Supervisors</span>
          <span v-if="role == 'agent'">Agents</span>
          <span v-if="role == 'custom'">Custom users</span>
          <span v-if="role == 'user'">Students</span>
          <span v-if="role == 'manager'">Managers</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">
            <span v-if="role == 'admin'">Administrators</span>
            <span v-if="role == 'supervisor'">Supervisors</span>
            <span v-if="role == 'agent'">Agents</span>
            <span v-if="role == 'custom'">Custom users</span>
            <span v-if="role == 'user'">Students</span>
            <span v-if="role == 'manager'">Managers</span>
          </div>
          <div class="card-body-">
            <vue-good-table
              :columns="columns"
              :rows="users"
              :line-numbers="true"
              :search-options="{
                enabled: true
              }"
              :pagination-options="{
                enabled: true,
                perPage: 100
              }"
              styleClass="vgt-table condensed myGoodTable"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'name'">
                  <router-link :to="{ name: routeName, params: { uuid: props.row.uuid } }">
                    <b>{{ props.row.name }}</b>
                  </router-link>
                </span>

                <span v-if="props.column.field == 'email'">{{ props.row.email }}</span>
                <span v-if="props.column.field == 'phone'">{{ props.row.phone }}</span>

                <span v-if="props.column.field == 'createdAt'">{{ props.row.createdAt | formatDateTime }}</span>
              </template>

              <div slot="table-actions" v-if="authUserRole == 'admin'">
                <router-link :to="{ name: 'CreateUserAdmin' }" class="btn btn-success btn-sm mr-3">Create</router-link>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'UsersAdmin',

  data() {
    return {
      users: [
        {
          role: { name: '' },
          categories: [{ name: '' }],
          subCategories: [{ name: '' }]
        }
      ],
      role: ''
    }
  },

  watch: {
    '$route.params.role': function(role) {
      this.loadUsers(role)
      this.role = role
      window.scrollTo(0, 0)
    }
  },

  mounted() {
    let role = 'agent'
    if (this.$route.params.role != undefined) {
      role = this.$route.params.role
    }
    this.loadUsers(role)
    this.role = role
  },

  methods: {
    loadUsers(role) {
      let loading = this.$loading.show()
      axios.get(`/${this.authUserRole}/users?role=${role}`).then(res => {
        if (res.data.status == 'success') {
          this.users = res.data.users
        }
        loading.hide()
      })
    }
  },

  computed: {
    columns() {
      /* let roleLable = "";
      if (this.role == "agent") {
        roleLable = this.client.topCategoryLabel;
      } else if (this.role == "supervisor") {
        roleLable = this.client.subCategoryLabel;
      } */
      let cols = [
        {
          label: 'Name',
          field: 'name'
        },
        /*  {
          label: 'Matric No',
          field: 'matric'
        }, */
        {
          label: 'Email',
          field: 'email'
        },
        {
          label: 'Phone',
          field: 'phone'
        },
        {
          label: 'Date/Time',
          field: 'createdAt'
        }
      ]
      return cols
    },

    client() {
      return this.$store.state.client
    },

    authUserRole() {
      return this.$store.state.authUser.Role
    },

    routeName() {
      if (this.$store.state.authUser.Role === 'admin') {
        return 'UserDetailsAdmin'
      } else {
        return 'UserDetailsManager'
      }
    }
  }
}
</script>
