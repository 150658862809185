<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <List routeName="TicketDetailsCustom" />
      </div>
    </div>
  </div>
</template>

<script>
//import axios from 'axios'
import List from '@/components/List.vue'
import BackIcon from '@/components/BackIcon.vue'

export default {
  name: 'TicketsCustom',

  data() {
    return {
      /*   spinner: false,
      tickets: [],
      status: '',
      totalTickets: '',
      label: 'Tickets' */
    }
  },

  watch: {
    /* '$route.params.status': function(status) {
      this.loadTickets(status)
      this.status = status
      window.scrollTo(0, 0)
    } */
  },

  mounted() {
    /* let status = 'all'
    if (this.$route.params.status != undefined) {
      status = this.$route.params.status
    }
    this.loadTickets(status)
    this.status = status */
  },

  methods: {
    /* loadTickets(status) {
      let path = '/custom/tickets?status=' + status
      let searchType = this.$route.query.searchType
      let searchTerm = this.$route.query.searchTerm
      if (searchType !== '' && searchType !== undefined) {
        path = `/custom/search-tickets?searchType=${searchType}&searchTerm=${searchTerm}`
      }

      this.spinner = true
      axios.get(path).then(res => {
        if (res.data.status == 'success') {
          this.tickets = res.data.tickets
          this.totalTickets = res.data.totalTickets
          this.label = res.data.label
        } else {
          //this.$swal("Ooops!", res.data.message, "error");
          this.tickets = []
        }
        this.spinner = false
      })
    },
    searchTickets(data) {
      this.tickets = data.tickets
      this.label = data.label
      this.totalTickets = data.tickets.length
      //this.$store.state.pageTicketList = data.tickets
    } */
  },

  components: {
    List,
    // ChatAuthor,
    BackIcon
  }
}
</script>

<style></style>
