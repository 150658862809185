import OfficeScreen from '../layouts/OfficeScreen.vue'
import HomeOfficeScreen from '../views/officeScreen/Home.vue'
import DashboardOfficeScreen from '../views/officeScreen/Dashboard.vue'
import DashboardOfficeScreen2 from '../views/officeScreen/Dashboard2.vue'
import TicketsOfficeScreen from '../views/officeScreen/Tickets.vue'

const officeScreenRoutes = [
  {
    //path: '/office-screen',
    path: '/ffcscn',
    component: OfficeScreen,
    children: [
      /* core */
      /* {
        path: '/',
        name: 'HomeOfficeScreen',
        component: HomeOfficeScreen
      },
      {
        path: 'dashboard',
        name: 'DashboardOfficeScreen',
        component: DashboardOfficeScreen
      }, */
      {
        path: '/',
        name: 'DashboardOfficeScreen2',
        component: DashboardOfficeScreen2
      }
      /* {
        path: 'tickets',
        name: 'TicketsOfficeScreen',
        //component: () => import('../views/officeScreen/Tickets.vue'),
        component: TicketsOfficeScreen
      } */
    ]
  }
]

export default officeScreenRoutes
