<template>
  <div id="EditSubCategoryAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Update</div>
      </div>
    </div>

    <main>
      <div class="container-fluid mt-n10">
        <div class="row">
          <div class="col-md-12">
            <div class="card mb-4">
              <div class="card-header">Update {{ name }}</div>
              <div class="card-body">
                <div class="col-md-8">
                  <!-- <h3>Please provide Agent name</h3> -->

                  <form @submit.prevent="updateUser">
                    <div class="form-group">
                      <label for="role">Roles</label>
                      <select v-model="roleSlug" :class="{ 'is-invalid': $v.roleSlug.$error, 'is-valid': !$v.roleSlug.$invalid }" class="form-control">
                        <option selected value>Select ...</option>
                        <option v-for="role in roles" :key="role.slug" :value="role.slug">
                          <!-- {{role.name}} -->
                          <span v-if="role.slug === 'user'">Student</span>
                          <span v-else>{{ role.name }}</span>
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <span v-if="!$v.roleSlug.required">Role is required.</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="name">Name</label>
                      <input type="text" v-model="name" class="form-control" placeholder="e.g. John Smith" :class="{ 'is-invalid': $v.name.$error, 'is-valid': !$v.name.$invalid }" />
                      <div class="invalid-feedback">
                        <span v-if="!$v.name.required">Name is required.</span>
                        <span v-if="!$v.name.minLength">Name must be at least {{ $v.name.$params.minLength.min }} letters.</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="email">Email</label>
                      <input type="text" v-model="email" class="form-control" placeholder="e.g. john@gmail.com" :class="{ 'is-invalid': $v.email.$error, 'is-valid': !$v.email.$invalid }" />
                      <div class="invalid-feedback">
                        <span v-if="!$v.email.required">Email is required.</span>
                        <span v-if="!$v.email.email">Email must be valid email address.</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="phone">Phone number</label>
                      <input type="text" v-model="phone" class="form-control" placeholder="e.g. 08011223344" />
                      <!-- <input type="text" v-model="phone" class="form-control" placeholder="e.g. 08011223344" :class="{ 'is-invalid': $v.phone.$error, 'is-valid': !$v.phone.$invalid }" />
                      <div class="invalid-feedback">
                        <span v-if="!$v.phone.required">Phone is required.</span>
                        <span v-if="!$v.phone.numeric">Phone must be numeric.</span>
                        <span v-if="!$v.phone.minLength">Phone must be at least {{ $v.phone.$params.minLength.min }} digits.</span>
                        <span v-if="!$v.phone.maxLength">Phone must be at most {{ $v.phone.$params.maxLength.max }} digits.</span>
                      </div> -->
                    </div>

                    <button type="submit" class="btn btn-danger btn-block">Update user</button>
                  </form>
                  <!-- / Create form -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from 'axios'
import { required, email, numeric, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'EditUserAdmin',

  data() {
    return {
      roles: [],
      roleSlug: '',
      name: '',
      email: '',
      phone: 0
    }
  },

  mounted() {
    let loading = this.$loading.show()
    axios.get('/admin/roles').then(res => {
      this.roles = res.data.roles
    })

    axios.get(`/admin/user/${this.$route.params.uuid}`).then(res => {
      this.name = res.data.user.name
      this.email = res.data.user.email
      this.phone = res.data.user.phone
      this.roleSlug = res.data.user.role.slug
    })
    loading.hide()
  },

  methods: {
    updateUser() {
      let loading = this.$loading.show()
      this.$v.$touch()
      if (this.$v.$invalid) {
        loading.hide()
        return
      }
      let formData = new FormData()
      formData.append('name', this.name)
      formData.append('email', this.email)
      formData.append('phone', this.phone)
      formData.append('role', this.roleSlug)
      formData.append('uuid', this.$route.params.uuid)
      formData.append('originURL', window.location.origin)
      axios.post('admin/user/edit', formData).then(res => {
        if (res.data.status == 'success') {
          //res.data.category.subCategories = [];
          //this.categories.push(res.data.category);
          this.$swal('', `${this.name} updated successfully`, 'success')
          this.$router.push({ name: 'UserDetailsAdmin', params: { uuid: this.$route.params.uuid } })
        } else {
          this.$swal('', res.data.message, 'error')
          //console.log(res.data.validation)
        }
        loading.hide()
      })
    },

    changeRole(e) {
      this.roleSlug = this.roles[e.target.value].slug
    }
  },

  computed: {
    client() {
      return this.$store.state.client
    }
  },

  validations: {
    name: { required, minLength: minLength(2) },
    email: { required, email },
    //phone: { required, numeric, minLength: minLength(11), maxLength: maxLength(11) },
    roleSlug: { required }
  }
}
</script>
