<template>
  <div class="row">
    <!-- total -->
    <div class="col">
      <div class="box bgAll">
        <div class="box-title">
          Raised
          <!-- <small>{{ label }}</small> -->
        </div>
        <div class="box-figure" v-show="totalRaised > 0">{{ totalRaised | thousandSeparator }}</div>
        <div class="box-figure" v-show="totalRaised < 1">0</div>
      </div>
    </div>
    <!-- open -->
    <div class="col">
      <div class="box bgOpen">
        <div class="box-title">
          Open
          <!-- <small>{{ label }}</small> -->
        </div>
        <div class="box-figure" v-show="open > 0">{{ open | thousandSeparator }}</div>
        <div class="box-figure" v-show="open < 1">0</div>
      </div>
    </div>
    <!-- InProgress -->
    <div class="col">
      <div class="box bgInProgress">
        <div class="box-title">
          In Progress
          <!-- <small>{{ label }}</small> -->
        </div>
        <div class="box-figure" v-show="in_progress > 0">{{ in_progress | thousandSeparator }}</div>
        <div class="box-figure" v-show="in_progress < 1">0</div>
      </div>
    </div>
    <!-- escalated -->
    <div class="col">
      <div class="box bgEscalated">
        <div class="box-title">
          Escalated
          <!-- <small>{{ label }}</small> -->
        </div>
        <div class="box-figure" v-show="escalated > 0">{{ escalated | thousandSeparator }}</div>
        <div class="box-figure" v-show="escalated < 1">0</div>
      </div>
    </div>
    <!-- closed -->
    <div class="col">
      <div class="box bgClosed">
        <div class="box-title">
          Closed
          <!-- <small>{{ label }}</small> -->
        </div>
        <div class="box-figure" v-show="closed > 0">{{ closed | thousandSeparator }}</div>
        <div class="box-figure" v-show="closed < 1">0</div>
      </div>
    </div>
    <!-- reopened -->
    <div class="col">
      <div class="box bgReopened">
        <div class="box-title">
          Reopened
          <!-- <small>{{ label }}</small> -->
        </div>
        <div class="box-figure" v-show="reopened > 0">{{ reopened | thousandSeparator }}</div>
        <div class="box-figure" v-show="reopened < 1">0</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'PerformanceReport',

  data() {
    return {
      open: 0,
      in_progress: 0,
      escalated: 0,
      closed: 0,
      reopened: 0,
      totalRaised: 0
    }
  },

  /*   props: {
    reports: Object
  } */
  mounted() {
    /* let status = 'all'
    if (this.$route.params.status != undefined) {
      status = this.$route.params.status
    }
    this.loadTickets(status)
    this.status = status */
    axios.get('/agent/performance-report').then(res => {
      if (res.data.status === 'success') {
        this.open = res.data.open
        this.in_progress = res.data.in_progress
        this.escalated = res.data.escalated
        this.closed = res.data.closed
        this.reopened = res.data.reopened
        this.totalRaised = res.data.totalRaised
      }
    })
  }
}
</script>

<style></style>
