<template>
  <!-- 
modal-open to body
show and display to div.modal
  -->
  <div id="AssignUserToSubItem">
    <button v-b-modal="customRoleItem.uuid" class="btn btn-xs btn-outline-secondary" title="Assign / unassign user">Users</button>

    <!-- arrow-right -->
    <b-modal centered :id="customRoleItem.uuid" size="md" hide-footer no-close-on-backdrop>
      <template #modal-title> {{ customRoleItem.name }} </template>
      <div class="itemList">
        <table class="table table-sm">
          <tr v-for="(user, index) in customRoleItem.users" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>
              <button class="btn btn-xs btn-outline-secondary" @click="deleteAssignUser(user.uuid, index)">Unassign</button>
            </td>
          </tr>
        </table>

        <form @submit.prevent="assignedUser" class="mt-4">
          <div class="form-group">
            <select v-model="userSelected" class="form-control">
              <option selected value>Select ...</option>
              <option v-for="(user, index) in users" :key="user.uuid" :value="user.uuid">{{ index + 1 }}. {{ user.name }} - {{ user.email }}</option>
            </select>
          </div>
          <button class="btn btn-success">Assign user</button>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AssignUserToSubItem',

  data() {
    return {
      userSelected: '',
      //modalID: "add-user-to-sub-item",
      users: []
    }
  },

  props: {
    customRoleItem: { type: Object } // selected item from the list.
  },

  mounted() {
    axios.get('/admin/users?role=custom').then(res => {
      if (res.data.status == 'success') {
        const a = res.data.users
        const b = this.customRoleItem.users
        const availableUsers = a.filter(function(objFromA) {
          return !b.find(function(objFromB) {
            return objFromA.email === objFromB.email
          })
        })
        this.users = availableUsers // res.data.users
      }
    })
  },

  methods: {
    assignedUser() {
      let loading = this.$loading.show()
      let formData = new FormData()
      formData.append('userUUID', this.userSelected)
      formData.append('customRoleItemUUID', this.customRoleItem.uuid)
      axios.post('admin/custom-role-item/assign-user', formData).then(res => {
        if (res.data.status == 'success') {
          if (this.customRoleItem.users == null) {
            this.customRoleItem.users = []
          }
          //this.customRoleItem.users.push(res.data.user);
          //this.$swal("", res.data.message, "success");
          //this.userSelected = "";
          window.location.reload()
        } else {
          this.$swal('', res.data.message, 'error')
        }
        loading.hide()
      })
    },

    deleteAssignUser(uuid, index) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'Unassign: ' + this.customRoleItem.users[index].name,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        })
        .then(result => {
          if (result.value) {
            /* actural action */
            let loading = this.$loading.show()

            let formData = new FormData()
            formData.append('userUUID', uuid)
            formData.append('customRoleItemUUID', this.customRoleItem.uuid)

            axios.post('/admin/custom-role-item/unassign-user', formData).then(res => {
              if (res.data.status == 'success') {
                //this.$swal.fire("Unassigned!", `${this.customRoleItem.users[index].name} deleted`, "success");
                //this.customRoleItem.users.splice(index, 1);
                window.location.reload()
              } else {
                this.$swal('', res.data.message, 'error')
              }
              loading.hide()
            })
          }
        })
    }
  }
}
</script>

<style></style>
