<template>
  <div class="PasswordResetNew">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-sm-12 col-12">
          <div class="card mb-3">
            <div class="card-header"><BackIcon /><b-icon-key v-if="!$routerHistory.hasPrevious()"></b-icon-key>Reset Password</div>
            <div class="card-body">
              <p>Please enter your new password</p>
              <form @submit.prevent="resetPassword">
                <div class="form-group mb-3">
                  <label>New Password</label>
                  <input v-model="newPassword" type="password" placeholder="new password" class="form-control form-control-lg" :class="{ 'is-invalid': $v.newPassword.$error, 'is-valid': !$v.newPassword.$invalid }" />
                  <div class="invalid-feedback">
                    <span v-if="!$v.newPassword.required">New password is required.</span>
                    <span v-if="!$v.newPassword.minLength">New password must be at least {{ $v.newPassword.$params.minLength.min }} characters.</span>
                  </div>
                </div>

                <div class="form-group mb-3">
                  <label>Confirm Password</label>
                  <input v-model="confirmPassword" type="password" placeholder="confirm password" class="form-control form-control-lg" :class="{ 'is-invalid': $v.confirmPassword.$error, 'is-valid': !$v.confirmPassword.$invalid }" />
                  <div class="invalid-feedback">
                    <span v-if="!$v.confirmPassword.required">Confirm password is required.</span>
                    <span v-if="!$v.confirmPassword.sameAs">Passwords must be identical.</span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <b-spinner v-if="spinner" variant="success" label="Spinning"></b-spinner>
                    <button v-if="!spinner" type="submit" class="btn btn-danger">Reset Password</button>
                  </div>
                  <!-- /.col -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import BackIcon from "@/components/BackIcon.vue";

export default {
  name: "PasswordResetNew",
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      spinner: false,
    };
  },

  methods: {
    resetPassword() {
      this.spinner = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.spinner = false;
        return;
      }

      let form = new FormData();
      form.append("token", this.$route.params.token);
      form.append("newPassword", this.newPassword);
      form.append("confirmPassword", this.confirmPassword);
      form.append("originURL", window.location.origin);

      axios
        .post("/auth/password-reset/token", form)
        .then((res) => {
          if (res.data.status == "success") {
            this.$swal("", res.data.message, "success");
            this.$router.push("/login");
          } else {
            this.$swal("", res.data.message, "error");
          }
          this.spinner = false;
        })
        .catch((err) => {
          this.$swal("", "Unable to send request.", "error");
          this.spinner = false;
        });
    },
  },

  validations: {
    newPassword: { required, minLength: minLength(7) },
    confirmPassword: { required, sameAs: sameAs("newPassword") },
  },
  components: { BackIcon },
};
</script>



