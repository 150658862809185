<template>
  <div id="TicketsAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Tickets (Group)</div>
        <!-- <div class="subPageTitle" v-if="status != 'all'">{{ status }}</div> -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <ListGroup :routeName="routeName" />
      </div>
    </div>
  </div>
</template>

<script>
import ListGroup from '@/components/ListGroup.vue'

export default {
  name: 'TicketsGroupAdmin',

  data() {
    return {}
  },

  methods: {},

  components: {
    ListGroup
  },

  computed: {
    authUserRole() {
      return this.$store.state.authUser.Role
    },
    routeName() {
      if (this.$store.state.authUser.Role === 'admin') {
        return 'TicketDetailsAdmin'
      } else {
        return 'TicketDetailsManager'
      }
    }
  }
}
</script>
<style></style>
