<template>
  <div id="CustomRolesAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Custom Roles</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">Custom Roles</div>
          <div class="card-body-">
            <vue-good-table
              :columns="columns"
              :rows="customRoles"
              :line-numbers="true"
              :search-options="{
                enabled: true
              }"
              :pagination-options="{
                enabled: true,
                perPage: 20
              }"
              styleClass="vgt-table condensed myGoodTable"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'name'">
                  <router-link :to="{ name: routeName, params: { uuid: props.row.uuid } }">{{ props.row.name }}</router-link>
                </span>
                <span v-if="props.column.field == 'description'">{{ props.row.description }}</span>
                <span v-if="props.column.field == 'edit' && authUserRole == 'admin'">
                  <router-link :to="{ name: 'EditCustomRoleAdmin', params: { uuid: props.row.uuid } }" class="btn btn-xs btn-outline-secondary" title="Update">edit</router-link>
                </span>
                <span v-if="props.column.field == 'delete' && authUserRole == 'admin'">
                  <button @click="deleteCustomRole(props.row.uuid, props.row.originalIndex)" class="btn btn-xs btn-outline-secondary" title="Delete">delete</button>
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
//import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: 'CustomRolesAdmin',

  data() {
    return {
      customRoles: [],
      name: '',
      description: ''
    }
  },

  mounted() {
    let loading = this.$loading.show()
    axios.get(`/${this.authUserRole}/custom-roles`).then(res => {
      if (res.data.status == 'success') {
        this.customRoles = res.data.customRoles
      }
      loading.hide()
    })
  },

  methods: {
    deleteCustomRole(uuid, index) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'DELETE: ' + this.customRoles[index].name,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        })
        .then(result => {
          if (result.value) {
            /* actural action */
            let loading = this.$loading.show()

            axios.post(`/admin/custom-role/delete/${uuid}`).then(res => {
              if (res.data.status == 'success') {
                this.$swal.fire('Deleted!', `${this.customRoles[index].name} deleted`, 'success')
                this.customRoles.splice(index, 1)
              } else {
                this.$swal('', res.data.message, 'error')
              }
              loading.hide()
            })
            /* end of actural action */
          }
        })
    }
  },

  computed: {
    columns() {
      let cols = [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Description',
          field: 'description',
          sortable: false
        },
        {
          label: '',
          field: 'edit',
          sortable: false
        },
        {
          label: '',
          field: 'delete',
          sortable: false
        }
      ]
      return cols
    },

    client() {
      return this.$store.state.client
    },

    authUserRole() {
      return this.$store.state.authUser.Role
    },

    routeName() {
      if (this.$store.state.authUser.Role === 'admin') {
        return 'CustomRoleDetailsAdmin'
      } else {
        return 'CustomRoleDetailsManager'
      }
    }
  }
}
</script>
