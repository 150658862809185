<template>
  <div id="CreateForm">
    <form @submit.prevent="create">
      <div class="form-group">
        <label for="category">
          Category
          <span class="text-danger">*</span>
        </label>
        <select v-model="form.categoryID" class="form-control form-control-lg">
          <option value="Select..." selected>Select...</option>
          <option v-for="item in categories" :key="item.uuid" :value="item.uuid">{{ item.name }}</option>
        </select>
        <div class="text-danger text-xs" v-show="$v.form.categoryID.$error">
          <span v-if="!$v.form.categoryID.required">Category is required.</span>
        </div>
      </div>

      <div class="form-group">
        <label for="message">
          Question
          <span class="text-danger">*</span>
        </label>
        <textarea v-model="form.question" class="form-control" rows="2"></textarea>
        <div class="text-danger text-xs" v-show="$v.form.question.$error">
          <span v-if="!$v.form.question.required">Question is required.</span>
        </div>
      </div>
      <div class="form-group">
        <label for="message">
          Answer
          <span class="text-danger">*</span>
        </label>
        <vue-editor v-model="form.answer" :editorToolbar="editorToolbar"></vue-editor>
        <div class="text-danger text-xs" v-show="$v.form.question.$error">
          <span v-if="!$v.form.answer.required">Answer is required.</span>
          <span v-if="!$v.form.answer.minLength">Answer must be at least {{ $v.form.answer.$params.minLength.min }} letters.</span>
        </div>
      </div>
      <button type="submit" class="btn btn-danger btn-lg">Create</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { VueEditor } from 'vue2-editor'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'CreateForm',

  props: {
    categories: Array
  },

  data() {
    return {
      form: {
        categoryID: '',
        question: '',
        answer: ''
      },

      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
    }
  },

  methods: {
    create() {
      let loading = this.$loading.show()
      this.$v.$touch()
      if (this.$v.$invalid) {
        loading.hide()
        return
      }
      let formData = new FormData()
      formData.append('categoryID', this.form.categoryID)
      formData.append('question', this.form.question)
      formData.append('answer', this.form.answer)
      axios.post(`/${this.authUserRole}/faq/create`, formData).then(res => {
        if (res.data.status == 'success') {
          this.$v.$reset()
          this.$emit('newFAQ', res.data.faq)
          this.form = {}
          //window.location.reload()
        }
        loading.hide()
      })
    }
  },

  validations: {
    form: {
      categoryID: { required },
      question: { required },
      answer: { required, minLength: minLength(10) }
    }
  },

  components: {
    VueEditor
  },

  computed: {
    authUserRole() {
      return this.$store.state.authUser.Role
    }
  }
}
</script>
