<template>
  <div id="CategoriesAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Categories</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">Categories</div>
          <div class="card-body-">
            <vue-good-table
              :columns="columns"
              :rows="categories"
              :line-numbers="true"
              :search-options="{
                enabled: true
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: [{ field: 'name', type: 'asc' }]
              }"
              styleClass="vgt-table condensed myGoodTable"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'name'">
                  <router-link :to="{ name: routeName, params: { uuid: props.row.uuid } }">
                    {{ props.row.name }}
                  </router-link>
                </span>
                <span v-if="props.column.field == 'subcategoriesTotal'">{{ props.row.subcategoriesTotal }}</span>
                <span v-if="props.column.field == 'usersTotal'">{{ props.row.usersTotal }}</span>
                <span v-if="props.column.field == 'description'">{{ props.row.description }}</span>
                <span v-if="props.column.field == 'usersbutton'">
                  <AssignSupervisor :category="props.row" />
                </span>
                <span v-if="props.column.field == 'edit' && authUserRole == 'admin'">
                  <router-link :to="{ name: 'EditCategoryAdmin', params: { uuid: props.row.uuid } }" class="btn btn-xs btn-outline-secondary" title="Update">edit</router-link>
                </span>
                <span v-if="props.column.field == 'delete' && authUserRole == 'admin'">
                  <button @click="deleteCategory(props.row.uuid, props.row.originalIndex)" class="btn btn-xs btn-outline-secondary" title="Delete">delete</button>
                </span>
              </template>
              <div slot="table-actions" v-if="authUserRole == 'admin'">
                <router-link :to="{ name: 'CreateCategoryAdmin' }" class="btn btn-success btn-sm mr-3">Create</router-link>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
//import { required, minLength } from "vuelidate/lib/validators";
import AssignSupervisor from '@/components/AssignSupervisor.vue'

export default {
  name: 'CategoriesAdmin',

  components: {
    AssignSupervisor
  },

  data() {
    return {
      categories: [],
      name: '',
      description: ''
    }
  },

  mounted() {
    let loading = this.$loading.show()
    axios.get(`/${this.authUserRole}/categories`).then(res => {
      if (res.data.status == 'success') {
        this.categories = res.data.categories
      }
      loading.hide()
    })
  },

  methods: {
    deleteCategory(uuid, index) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'DELETE: ' + this.categories[index].name,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        })
        .then(result => {
          if (result.value) {
            /* actural action */
            let loading = this.$loading.show()

            axios.post(`/admin/category/delete/${uuid}`).then(res => {
              if (res.data.status == 'success') {
                this.$swal.fire('Deleted!', `${this.categories[index].name} deleted`, 'success')
                this.categories.splice(index, 1)
              } else {
                this.$swal('', res.data.message, 'error')
              }
              loading.hide()
            })
            /* end of actural action */
          }
        })
    }
  },

  computed: {
    columns() {
      let cols = [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Subcategories',
          field: 'subcategoriesTotal',
          type: 'number',
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'Assigned Supervisors',
          field: 'usersTotal',
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'Description',
          field: 'description',
          sortable: false
        },
        {
          label: '',
          field: 'usersbutton',
          sortable: false
        },
        {
          label: '',
          field: 'edit',
          sortable: false
        },

        {
          label: '',
          field: 'delete',
          sortable: false
        }
      ]
      return cols
    },

    client() {
      return this.$store.state.client
    },

    authUserRole() {
      return this.$store.state.authUser.Role
    },

    routeName() {
      if (this.$store.state.authUser.Role === 'admin') {
        return 'CategoryDetailsAdmin'
      } else {
        return 'CategoryDetailsManager'
      }
    }
  }
}
</script>
