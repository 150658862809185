<template>
  <div class="container">
    <!-- Agents -->
    <div class="row">
      <div class="col-10 offset-1">
        <div class="card mb-3">
          <div class="card-header"><!-- <BackIcon />  -->Active Agents</div>
          <div class="card-body">
            <table class="table table-striped table-sm">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Categories</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(agent, i) in agents" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>
                    <router-link :to="{ name: 'TicketsSupervisor', query: { searchType: 'assignedTo', searchTerm: agent.uuid } }">
                      {{ agent.name }}
                    </router-link>
                  </td>
                  <td>{{ agent.email }}</td>
                  <td>{{ agent.phone }}</td>
                  <td>
                    <!-- collapsable  -->
                    <div>
                      <b-button v-b-toggle="'active-collapse-' + i" variant="outline-secondary" size="sm">Assigned categories </b-button>
                      <b-collapse :id="'active-collapse-' + i" class="mt-2">
                        <ul class="ml-3">
                          <li v-for="sub in agent.subCategories">
                            <span class="smallText">{{ sub.category.name }}</span>
                            {{ sub.name }}
                            <!-- <span style="font-size:.7rem"> - </span> -->
                          </li>
                        </ul>
                      </b-collapse>
                    </div>
                    <!-- / collapsable -->
                  </td>
                  <td>
                    <button class="btn btn-sm btn-outline-danger" @click="deactivate(agent.uuid)">Deactivate</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- / Agents -->

    <!-- Deleted Agents -->
    <div class="row">
      <div class="col-10 offset-1">
        <div class="card mb-3">
          <div class="card-header" style="background-color: #6c757d; border:none;">Deactivated Agents</div>
          <div class="card-body">
            <table class="table table-striped table-sm">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Categories</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(agent, i) in agentsDeleted" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>
                    <router-link :to="{ name: 'TicketsSupervisor', query: { searchType: 'assignedTo', searchTerm: agent.uuid } }">
                      {{ agent.name }}
                    </router-link>
                  </td>
                  <td>{{ agent.email }}</td>
                  <td>{{ agent.phone }}</td>
                  <td>
                    <!-- collapsable  -->
                    <div>
                      <b-button v-b-toggle="'collapse-' + i" variant="outline-secondary" size="sm">Assigned categories </b-button>
                      <b-collapse :id="'collapse-' + i" class="mt-2">
                        <ul class="ml-3">
                          <li v-for="sub in agent.subCategories">
                            <span class="smallText">{{ sub.category.name }}</span>
                            {{ sub.name }}
                            <!-- <span style="font-size:.7rem"> - </span> -->
                          </li>
                        </ul>
                      </b-collapse>
                    </div>
                    <!-- / collapsable -->
                  </td>
                  <td>
                    <button class="btn btn-sm btn-outline-secondary" @click="activate(agent.uuid)">Activate</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- / Deleted Agents -->
  </div>
</template>

<script>
import BackIcon from '@/components/BackIcon.vue'
import axios from 'axios'

export default {
  components: { BackIcon },

  name: 'AgentsListSupervisor',

  data() {
    return {
      agents: [],
      agentsDeleted: []
    }
  },

  mounted() {
    axios
      .get('/supervisor/my-agents')
      .then(res => {
        if (res.data.status == 'success') {
          this.agents = res.data.agents
          this.agentsDeleted = res.data.agentsDeleted
        } else {
          this.$swal('Ooops!', res.data.message, 'error')
        }
      })
      .catch(error => {})
  },

  methods: {
    // do delete
    deactivate(uuid) {
      const user = this.agents.find(u => u.uuid == uuid)
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: `Deactivate: ${user.name}'s account`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        })
        .then(result => {
          if (result.value) {
            /* actural action */
            let loading = this.$loading.show()
            axios.post(`/user/deactivate?uuid=${user.uuid}`).then(res => {
              if (res.data.status == 'success') {
                this.agentsDeleted.push(user)
                this.agents = this.agents.filter(u => u.uuid !== uuid)
                this.$swal('', res.data.message, 'success')
              } else {
                this.$swal('', res.data.message, 'error')
              }
              loading.hide()
            })
            /* end of actural action */
          }
        })
    },

    /* undo deleted */
    activate(uuid) {
      const user = this.agentsDeleted.find(u => u.uuid == uuid)
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: `Activate: ${user.name}'s account`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        })
        .then(result => {
          if (result.value) {
            /* actural action */
            let loading = this.$loading.show()
            axios.post(`/user/activate?uuid=${user.uuid}`).then(res => {
              if (res.data.status == 'success') {
                this.agents.push(user)
                this.agentsDeleted = this.agentsDeleted.filter(u => u.uuid !== uuid)
                this.$swal('', res.data.message, 'success')
              } else {
                this.$swal('', res.data.message, 'error')
              }
              loading.hide()
            })
            /* end of actural action */
          }
        })
    }
  }
}
</script>
