<template>
  <div id="StudentsAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">
          Users
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">
            Users
          </div>
          <div class="card-body-">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-per-page-change="onPerPageChange"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :columns="columns"
              :rows="students"
              :line-numbers="true"
              :pagination-options="{
                enabled: true,
                perPage: 100
              }"
              styleClass="vgt-table condensed myGoodTable"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'name'">
                  <router-link :to="{ name: routeName, params: { uuid: props.row.uuid } }">
                    <b>{{ props.row.name }}</b>
                  </router-link>
                </span>

                <span v-if="props.column.field == 'matric'">{{ props.row.matric }}</span>

                <span v-if="props.column.field == 'email'">{{ props.row.email }}</span>
                <span v-if="props.column.field == 'phone'">{{ props.row.phone }}</span>

                <span v-if="props.column.field == 'created_at'">{{ props.row.created_at | formatDateTime }}</span>
              </template>

              <div slot="table-actions">
                <table style="width: 100%">
                  <tr>
                    <td class="pr-1">
                      <select class="form-control form-control-sm" @change="searchCategory($event)">
                        <option selected="selected">search by ...</option>
                        <option value="matric">Matric</option>
                        <option value="name">Name</option>
                        <option value="email">Email</option>
                        <option value="phone">Phone</option>
                      </select>
                    </td>
                    <td class="pr-1">
                      <input
                        v-model="searchTerm"
                        type="text"
                        class="form-control form-control-sm"
                        id="inputPassword2"
                        :placeholder="searchPlaceholder"
                      />
                    </td>
                    <td class="pr-1">
                      <button class="btn btn-outline-secondary btn-sm" @click="searchHandler">Search</button>
                    </td>
                    <!-- <td v-if="authUserRole == 'admin'">
                      <router-link :to="{ name: 'CreateUserAdmin' }" class="btn btn-success btn-sm ml-4 mr-2">Create</router-link>
                      <router-link :to="{ name: 'StudentsUploadAdmin' }" class="btn  btn-link btn-sm ml-1 mr-2">Upload</router-link>
                    </td> -->
                  </tr>
                </table>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'StudentsAdmin',

  data() {
    return {
      students: [
        {
          role: { name: '' },
          categories: [{ name: '' }],
          subCategories: [{ name: '' }]
        }
      ],
      columns: [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Matric No',
          field: 'matric'
        },
        {
          label: 'Email',
          field: 'email'
        },
        {
          label: 'Phone',
          field: 'phone'
        },
        {
          label: 'Date/Time',
          field: 'created_at'
        }
      ],
      role: '',

      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 100
      },
      firstID: 0,
      lastID: 0,

      // custom search system
      searchBy: '',
      searchTerm: '',
      searchPlaceholder: 'search term'
    }
  },

  mounted() {
    axios
      .get(`/${this.authUserRole}/students?goodTableParams=${JSON.stringify(this.serverParams)}&firstID=${this.firstID}&lastID=${this.lastID}`)
      .then(res => {
        if (res.data.status == 'success') {
          this.students = res.data.students
          this.totalRecords = res.data.totalRecords
          this.firstID = res.data.students[0].id
          this.lastID = res.data.students[res.data.students.length - 1].id
        }
      })
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      //console.log('onSortChange', params)
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    /* onColumnFilter(params) {
      connsole.log('onColumnFilter: ', params)
      this.updateParams(params)
      this.loadItems()
    }, */

    // load items is what brings back the rows from server
    loadItems() {
      const params = JSON.stringify(this.serverParams)
      //console.log(params)
      axios.get(`/${this.authUserRole}/students?goodTableParams=${params}&firstID=${this.firstID}&lastID=${this.lastID}`).then(res => {
        if (res.data.status == 'success') {
          this.students = res.data.students
          this.totalRecords = res.data.totalRecords
        }
      })
    },

    // search system
    searchCategory(e) {
      let by = e.target.value
      this.searchBy = by
      this.searchPlaceholder = 'enter ' + by
    },

    // searchPost
    searchHandler() {
      let loading = this.$loading.show()
      let formData = new FormData()
      formData.append('searchBy', this.searchBy)
      formData.append('searchTerm', this.searchTerm)
      axios.post(`/${this.authUserRole}/students-search`, formData).then(res => {
        if (res.data.status == 'success') {
          this.students = res.data.students
          this.totalRecords = res.data.totalRecords
        }
        loading.hide()
      })
    }
    //
  },

  computed: {
    authUserRole() {
      return this.$store.state.authUser.Role
    },
    routeName() {
      if (this.$store.state.authUser.Role === 'admin') {
        return 'UserDetailsAdmin'
      } else {
        return 'UserDetailsManager'
      }
    }
  }
}
</script>
