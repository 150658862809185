<template>
  <div id="SubCategoriesAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Subcategories</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">Subcategories</div>
          <div class="card-body-">
            <vue-good-table
              :columns="columns"
              :rows="subCategories"
              :line-numbers="true"
              :search-options="{
                enabled: true
              }"
              :pagination-options="{
                enabled: true,
                perPage: 20
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: [{ field: 'name', type: 'asc' }]
              }"
              styleClass="vgt-table condensed myGoodTable"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'name'">{{ props.row.name }}</span>
                <span v-if="props.column.field == 'category'">
                  <router-link :to="{ name: routeName, params: { uuid: props.row.categoryUUID } }">
                    {{ props.row.category }}
                  </router-link>
                </span>
                <span v-if="props.column.field == 'usersTotal'">{{ props.row.usersTotal }}</span>
                <span v-if="props.column.field == 'description'">{{ props.row.description }}</span>
                <span v-if="props.column.field == 'usersbutton'">
                  <AssignAgent :subCategory="props.row" />
                </span>
                <span v-if="props.column.field == 'edit' && authUserRole == 'admin'">
                  <router-link :to="{ name: 'EditSubCategoryAdmin', params: { uuid: props.row.uuid } }" class="btn btn-xs btn-outline-secondary" title="Update">edit</router-link>
                </span>
                <span v-if="props.column.field == 'delete' && authUserRole == 'admin'">
                  <button @click="deleteSubCategory(props.row.uuid, props.row.originalIndex)" class="btn btn-xs btn-outline-secondary" title="Delete">delete</button>
                </span>
              </template>

              <div slot="table-actions">
                <router-link :to="{ name: 'CreateSubCategoryAdmin' }" class="btn btn-success btn-sm mr-3">Create</router-link>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AssignAgent from '@/components/AssignAgent.vue'

export default {
  name: 'SubCategoriesAdmin',

  components: {
    AssignAgent
  },

  data() {
    return {
      subCategories: [],
      role: ''
    }
  },

  mounted() {
    let loading = this.$loading.show()
    axios.get(`/${this.authUserRole}/sub-categories`).then(res => {
      if (res.data.status == 'success') {
        this.subCategories = res.data.subCategories
      }
      loading.hide()
    })
  },

  methods: {
    deleteSubCategory(uuid, index) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'DELETE: ' + this.subCategories[index].name,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        })
        .then(result => {
          if (result.value) {
            /* actural action */
            let loading = this.$loading.show()

            axios.post(`/admin/sub-category/delete/${uuid}`).then(res => {
              if (res.data.status == 'success') {
                this.$swal.fire('Deleted!', `${this.subCategories[index].name} deleted`, 'success')
                this.subCategories.splice(index, 1)
              } else {
                this.$swal('', res.data.message, 'error')
              }
              loading.hide()
            })
            /* end of actural action */
          }
        })
    }
  },

  computed: {
    columns() {
      let cols = [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Category',
          field: 'category'
        },

        {
          label: 'Assigned Agents',
          field: 'usersTotal',
          tdClass: 'text-center',
          thClass: 'text-center'
        },

        {
          label: 'Description',
          field: 'description',
          sortable: false
        },
        {
          label: '',
          field: 'usersbutton',
          sortable: false
        },
        {
          label: '',
          field: 'edit',
          sortable: false
        },
        {
          label: '',
          field: 'delete',
          sortable: false
        }
      ]
      return cols
    },
    client() {
      return this.$store.state.client
    },
    authUserRole() {
      return this.$store.state.authUser.Role
    },
    routeName() {
      if (this.$store.state.authUser.Role === 'admin') {
        return 'CategoryDetailsAdmin'
      } else {
        return 'CategoryDetailsManager'
      }
    }
  }
}
</script>
