<template>
  <span>
    <button @click="reActivateUser" class="btn btn-outline-secondary btn-sm ml-3">Reactivate</button>
  </span>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ReActivateUser',

  props: { user: Object },
  methods: {
    reActivateUser() {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: `You are about to reactivate ${this.user.name}'s account.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, do it!'
        })
        .then(result => {
          if (result.value) {
            /* actural action */
            let loading = this.$loading.show()

            axios.post(`/admin/user/reactivate/${this.user.uuid}`).then(res => {
              if (res.data.status == 'success') {
                this.$swal.fire('Done!', res.data.message, 'success')
                window.location.reload()
              } else {
                this.$swal('', res.data.message, 'error')
              }
              loading.hide()
            })
            /* end of actural action */
          }
        })
    }
  }
}
</script>

<style></style>
