<template>
  <div class="container mt-n10">
    <div class="row">
      <!--  -->
      <div class="col-lg-8 offset-lg-2 col-sm-12 offset-sm-12">
        <div class="card mb-3">
          <div class="card-header"><BackIcon /><b-icon-person v-if="!$routerHistory.hasPrevious()"></b-icon-person> User Profile</div>
          <div class="card-body">
            <table class="table table-striped ">
              <tr>
                <th>Name</th>
                <td>{{ user.name }}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{{ user.email }}</td>
              </tr>
              <tr>
                <th>Role</th>
                <td>{{ user.role.name }}</td>
              </tr>
              <tr v-if="user.role.slug == 'user'">
                <th>Matric</th>
                <td>{{ user.matric }}</td>
              </tr>
              <tr v-if="user.role.slug == 'supervisor'">
                <th>Assigned Categories</th>
                <td>
                  <ul v-for="(cat, i) in user.categories" :key="i">
                    <li>{{ cat.name }}</li>
                  </ul>
                </td>
              </tr>
              <tr v-if="user.role.slug == 'agent'">
                <th>Assigned Subcategories</th>
                <td>
                  <ul v-for="(cat, i) in user.subCategories" :key="i">
                    <li>
                      <span class="smallText">{{ cat.category.name }}</span>
                      {{ cat.name }}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr v-if="user.role.slug == 'custom'">
                <th>Assigned</th>
                <td>
                  <ul v-for="(cat, i) in user.customRoleItems" :key="i">
                    <li>
                      <span class="smallText">{{ cat.customRole.name }}</span>
                      {{ cat.name }}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr v-if="user.role.slug == 'user'">
                <th>Phone</th>
                <td>
                  <div v-if="user.phoneUpdate == 1">{{ user.phone }}</div>
                  <div v-if="user.phoneUpdate == 0">
                    <form class="form-inline" @submit.prevent="updatePhone">
                      <div class="form-group mx-sm-3">
                        <input v-model="newPhone" type="text" class="form-control form-control-sm" placeholder="e.g 08033775566" :class="{ 'is-invalid': $v.newPhone.$error, 'is-valid': !$v.newPhone.$invalid }" />
                        <div class="invalid-feedback">
                          <span v-if="!$v.newPhone.required">Phone is required.</span>
                          <span v-if="!$v.newPhone.numeric">Phone must be numeric.</span>
                          <span v-if="!$v.newPhone.minLength">Phone must be at least {{ $v.newPhone.$params.minLength.min }} digits.</span>
                          <span v-if="!$v.newPhone.maxLength">Phone must be at most {{ $v.newPhone.$params.maxLength.max }} digits.</span>
                        </div>
                      </div>
                      <b-spinner v-if="spinner" variant="success" label="Spinning"></b-spinner>
                      <button v-if="!spinner" type="submit" class="btn btn-sm btn-danger">update</button>
                    </form>
                  </div>
                </td>
              </tr>
            </table>

            <p>
              <router-link :to="'/' + authUser.Role + '/change-password'">Change Password</router-link>
              <!-- <a :href="'/' + authUser.Role + '/change-password'">Change Password</a>-->
              <!--  {{ authUser }} -->
            </p>

            <p>
              <Logout kind="link" />
            </p>
          </div>
        </div>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BackIcon from '@/components/BackIcon.vue'
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import Logout from '@/components/Logout.vue'

export default {
  name: 'Profile',

  components: {
    BackIcon,
    Logout
  },

  data() {
    return {
      spinner: false,
      user: { role: {} },
      newPhone: ''
    }
  },

  validations: {
    newPhone: { required, numeric, minLength: minLength(11), maxLength: maxLength(11) }
  },

  mounted() {
    let loading = this.$loading.show()
    let formData = new FormData()
    formData.append('email', this.authUser.Email)
    this.spinner = true
    axios.post('/user', formData).then(res => {
      this.user = res.data.user
      this.spinner = false
      loading.hide()
    })
  },

  methods: {
    updatePhone() {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }
      let formData = new FormData()
      formData.append('email', this.authUser.Email)
      formData.append('newPhone', this.newPhone)
      axios.post('/user/update-phone', formData).then(res => {
        if (res.data.status == 'success') {
          this.user = res.data.user
          window.location.reload()
        } else {
          this.$swal('Oops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    }
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    }
  }
}
</script>
