<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3 col-sm-8 offset-md-2">
        <div class="card mb-3">
          <div class="card-header"><BackIcon /><b-icon-key v-if="!$routerHistory.hasPrevious()"></b-icon-key> Change Password</div>
          <div class="card-body">
            <form @submit.prevent="handleSubmit">
              <div class="form-group">
                <label for="exampleInputEmail1">Current password</label>
                <input v-model="currentPassword" type="password" class="form-control" :class="{ 'is-invalid': $v.currentPassword.$error, 'is-valid': !$v.currentPassword.$invalid }" />
                <div class="invalid-feedback">
                  <span v-if="!$v.currentPassword.required">Current Password is required.</span>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">New password</label>
                <input v-model="newPassword" type="password" class="form-control" :class="{ 'is-invalid': $v.newPassword.$error, 'is-valid': !$v.newPassword.$invalid }" />
                <div class="invalid-feedback">
                  <span v-if="!$v.newPassword.required">New password is required.</span>
                  <span v-if="!$v.newPassword.minLength">New password must be at least {{ $v.newPassword.$params.minLength.min }} letters.</span>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Confirm new password</label>
                <input v-model="confirmPassword" type="password" class="form-control" :class="{ 'is-invalid': $v.confirmPassword.$error, 'is-valid': !$v.confirmPassword.$invalid }" />
                <div class="invalid-feedback">
                  <span v-if="!$v.newPassword.required">Confirm new password is required.</span>
                  <span v-if="!$v.confirmPassword.sameAs">New Passwords must be identical.</span>
                </div>
              </div>
              <b-spinner v-if="spinner" variant="success" label="Spinning"></b-spinner>
              <button v-if="!spinner" type="submit" class="btn btn-danger">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import BackIcon from '@/components/BackIcon.vue'

export default {
  name: 'ChangePassword',

  components: { BackIcon },

  data() {
    return {
      spinner: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  },

  validations: {
    currentPassword: { required },
    newPassword: { required, minLength: minLength(7) },
    confirmPassword: { required, sameAs: sameAs('newPassword') }
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    }
  },

  methods: {
    handleSubmit() {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }
      let formData = new FormData()
      formData.append('currentPassword', this.currentPassword)
      formData.append('newPassword', this.newPassword)
      formData.append('email', this.authUser.Email)

      axios.post('/user/reset-password', formData).then(res => {
        if (res.data.status == 'success') {
          this.$swal('Good!', res.data.message, 'success')
          this.$v.$reset()
          this.currentPassword = ''
          this.newPassword = ''
          this.confirmPassword = ''
        } else {
          this.$swal('Oops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    }
  }
}
</script>

<style></style>
