<template>
  <div id="ReassignTickets" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Reassign Tickets</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header"><BackIcon /> <b-icon-sticky v-if="!$routerHistory.hasPrevious()"></b-icon-sticky> Reassign Tickets</div>
          <div class="card-body">
            <div style="width: 100%; ">
              <!-- Search form -->
              <div style="width: 100%; display: flex; justify-content:space-between;">
                <div></div>
                <div>
                  <SearchForm :showExport="false" @searchTickets="searchTickets" @exportHandler="exportHandler"></SearchForm>
                </div>
              </div>

              <!-- Table -->
              <div>
                <vue-good-table
                  :totalRows="totalRecords"
                  :columns="columns"
                  :rows="tickets"
                  :line-numbers="true"
                  :pagination-options="{
                    enabled: true
                  }"
                  styleClass="vgt-table condensed myGoodTable"
                >
                  <template slot="table-row" slot-scope="props">
                    <!-- <span v-if="props.column.field == 'select'">
                      <span>
                        <b-form-checkbox inline>{{ props.row.id }}</b-form-checkbox>
                      </span>
                    </span> -->
                    <!-- <div v-if="props.column.field == 'ref'" style="width: 150px;">
                      <b-form-checkbox @click.prevent="selectFunc(props.row.id)" inline>
                        <b>{{ props.row.ref }}</b>
                      </b-form-checkbox>
                    </div> -->
                    <div v-if="props.column.field == 'ref'" style="width: 150px;">
                      <div style="display: flex; gap: 5px; align-items: center;">
                        <!-- <input type="checkbox" class=""  /> -->
                        <!-- <b-form-checkbox @change="selectFunc($event, props.row.id)"></b-form-checkbox> -->
                        <!-- <b-form-checkbox @click="selectFunc($event, props.row.id)"></b-form-checkbox> -->
                        <b-icon
                          v-if="ticketIDs.includes(props.row.id)"
                          @click="unSelectFunc(props.row.id)"
                          icon="check-square-fill"
                          class="checkIcon"
                          variant="primary"
                          style="width: 18px; height: 18px;"
                        ></b-icon>
                        <b-icon
                          v-else
                          @click="selectFunc(props.row.id)"
                          icon="square"
                          class="checkIcon"
                          variant="secondary"
                          style="width: 18px; height: 18px;"
                        ></b-icon>
                        <b>{{ props.row.ref }}</b>
                      </div>
                    </div>

                    <span v-if="props.column.field == 'status'">
                      <span v-if="props.row.status == 'Open'" class="badge badge-danger">{{ props.row.status }} </span>
                      <span v-if="props.row.status == 'Closed'" class="badge badge-success">{{ props.row.status }} </span>
                      <span v-if="props.row.status == 'Escalated'" class="badge badge-warning">{{ props.row.status }} </span>
                      <span v-if="props.row.status == 'Reopened'" class="badge badge-info">{{ props.row.status }} </span>
                      <span v-if="props.row.status == 'InProgress'" class="badge badge-primary">{{ props.row.status }} </span>
                    </span>

                    <span v-if="props.column.field == 'category'"> {{ props.row.category }} </span>
                    <span v-if="props.column.field == 'subcategory'">{{ props.row.subcategory }}</span>
                    <span v-if="props.column.field == 'email'">{{ props.row.email }}</span>
                    <span v-if="props.column.field == 'createdBy'">{{ props.row.createdBy }}</span>
                    <span v-if="props.column.field == 'assignedTo'">{{ props.row.assignedTo }}</span>
                    <span v-if="props.column.field == 'createdAt'">{{ props.row.createdAt | formatDateTime }}</span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
          <!-- Footer -->
          <div class="card-footer">
            <div class="reassignTickets">
              <label>Select agent</label>

              <select v-model="userID" class="form-control">
                <option value="Select..." selected>Select...</option>
                <option v-for="(user, index) in users" :key="index" :value="user.id">{{ user.name }} - ({{ user.email }})</option>
              </select>

              <b-button class="button" variant="danger" @click="reassignTickets">Reassign Tickets</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BackIcon from '@/components/BackIcon.vue'
import SearchForm from '@/components/SearchForm.vue'

export default {
  name: 'ReassignTickets',

  data() {
    return {
      tickets: [{ id: 0 }],
      totalRecords: 0,
      ticketIDs: [],
      users: [], // the agents
      userID: 0
    }
  },

  watch: {},

  mounted() {
    axios.get('/admin/users?role=agent').then(res => {
      if (res.data.status == 'success') {
        this.users = res.data.users
      }
      loading.hide()
    })
  },

  methods: {
    async searchTickets(data) {
      let params = {
        searchType: data.searchType,
        searchTerm: data.searchTerm,
        ticketStatusID: data.ticketStatusID,
        categoryID: data.categoryID,
        subcategoryID: data.subcategoryID,
        studycenterID: data.studycenterID,
        facultyID: data.facultyID,
        programmeID: data.programmeID,
        startDate: data.startDate,
        endDate: data.endDate
      }

      let path = 'search-tickets'

      await axios
        .get(`/admin/${path}`, {
          params: params
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.tickets = res.data.tickets
            this.totalRecords = res.data.totalRecords
          }
        })
    },

    exportHandler(data) {
      //console.log(data)
    },

    selectFunc(id) {
      this.ticketIDs.push(id)
    },

    unSelectFunc(id) {
      this.ticketIDs = this.ticketIDs.filter(item => item !== id)
    },

    async reassignTickets() {
      if (this.ticketIDs?.length == 0) {
        this.$swal('', 'Please select tickets', 'error')
        return
      }
      if (this.userID == 0 || this.userID == 'Select...' || this.userID == '' || this.userID == null || this.userID == undefined) {
        this.$swal('', 'Please select an agent', 'error')
        return
      }

      let formData = new FormData()
      formData.append('ticketIDs', JSON.stringify(this.ticketIDs))
      formData.append('userID', this.userID)
      await axios
        .post('/admin/reassign-tickets', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.tickets = []
            this.totalRecords = 0
            this.ticketIDs = []
            this.userID = 0
            this.$swal('Completed!', res.data.message, 'success')
          }
        })
    }
  },

  computed: {
    columns() {
      let cols = [
        {
          label: 'ReferenceID',
          field: 'ref'
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Category',
          field: 'category'
        },
        {
          label: 'Subcategory',
          field: 'subcategory'
        },
        {
          label: 'Email',
          field: 'email'
        },
        {
          label: 'AssignedTo',
          field: 'assignedTo'
        },
        {
          label: 'CreatedBy',
          field: 'createdBy'
        },
        {
          label: 'CreatedAt',
          field: 'createdAt'
        }
      ]
      return cols
    },

    inList() {
      return this.ticketIDs.includes(id)
    }
  },

  components: { BackIcon, SearchForm }
}
</script>

<style scoped>
.checkIcon {
  cursor: pointer;
}
.checkIcon:hover {
  fill: #007bff;
}

.reassignTickets {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 70%;
  /* background-color: #007bff; */
}

.reassignTickets label {
  font-weight: bold;
  width: auto;
}
.reassignTickets select {
  width: 350px;
}
.reassignTickets .button {
  width: 200px;
}

@media (max-width: 1280px) {
  .reassignTickets {
    width: 100%;
  }
}
@media (max-width: 960px) {
  .reassignTickets {
    flex-direction: column;
    width: 100%;
    height: 120px;
  }
  .reassignTickets select {
    width: 250px;
  }
  .reassignTickets .button {
    width: auto;
  }
}
@media (max-width: 600px) {
}
</style>
