<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 col-sm-12 col-12">
        <div class="card mb-3">
          <div class="card-header"><BackIcon /><b-icon-sticky v-if="!$routerHistory.hasPrevious()"></b-icon-sticky> Open New Ticket</div>
          <div class="card-body">
            <div v-if="spinner1" class="text-center">
              <b-spinner variant="secondary" label="Spinning"></b-spinner>
            </div>

            <div v-if="!spinner1">
              <!-- <p>
                Please enter your name number, select the issue category, valid phone number and message body. <br />
                <b>NOTE:</b> You will be contacted and notified via NelFund email (e.g. CSC/19/2000@nelfund.edu.ng) and phone provided.
              </p> -->

              <form @submit.prevent="handleSubmit" enctype="multipart/form-data">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="name">Name</label>
                      <input
                        type="text"
                        v-model="name"
                        class="form-control form-control-lg"
                        placeholder="e.g. John Doe"
                        :class="{ 'is-invalid': $v.name.$error, 'is-valid': !$v.name.$invalid }"
                      />
                      <div class="invalid-feedback">
                        <span v-if="!$v.name.required">Name is required.</span>
                        <span v-if="!$v.name.minLength">Name must be at least {{ $v.name.$params.minLength.min }} letters.</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input
                        type="text"
                        v-model="email"
                        class="form-control form-control-lg"
                        placeholder="e.g. john@gmail.com"
                        :class="{ 'is-invalid': $v.email.$error, 'is-valid': !$v.email.$invalid }"
                      />
                      <div class="invalid-feedback">
                        <span v-if="!$v.email.required">Email is required.</span>
                        <span v-if="!$v.email.email">Email must be valid email address.</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="name">Phone number</label>
                      <input
                        type="text"
                        v-model="phone"
                        class="form-control form-control-lg"
                        placeholder="e.g. 08011223344"
                        :class="{ 'is-invalid': $v.phone.$error, 'is-valid': !$v.phone.$invalid }"
                      />
                      <div class="invalid-feedback">
                        <span v-if="!$v.phone.required">Phone is required.</span>
                        <span v-if="!$v.phone.numeric">Phone must be numeric.</span>
                        <span v-if="!$v.phone.minLength">Phone must be at least {{ $v.phone.$params.minLength.min }} digits.</span>
                        <span v-if="!$v.phone.maxLength">Phone must be at most {{ $v.phone.$params.maxLength.max }} digits.</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <!--  -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="category">Category</label>
                      <select @change="topSelected($event)" class="form-control form-control-lg">
                        <option value="Select..." selected>Select...</option>
                        <option v-for="(item, index) in categories" :key="index" :value="index">{{ item.name }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="category">Subcategory</label>
                      <select
                        v-model="category"
                        class="form-control form-control-lg"
                        :class="{ 'is-invalid': $v.category.$error, 'is-valid': !$v.category.$invalid }"
                      >
                        <option value="Select..." selected>Select...</option>
                        <option v-for="(item, index) in subCategories" :key="index" :value="item.uuid">{{ item.name }}</option>
                        <!--  <optgroup v-for="(cat, i) in categories" :key="i" :label="cat.name">
                          <option v-for="(sub, j) in cat.subCategories" :key="j" :value="sub.uuid">{{ sub.name }}</option>
                        </optgroup> -->
                        <!-- End option group -->
                      </select>
                      <div class="invalid-feedback">
                        <span v-if="!$v.category.required">Issue category is required.</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="message">Message</label>
                  <vue-editor v-model="message" :editorToolbar="editorToolbar"></vue-editor>
                  <div class="text-danger text-xs" v-show="$v.message.$error" style="font-size: 13px;">
                    <span v-if="!$v.message.required">Message is required.</span>
                    <!-- <span v-if="!$v.message.minLength">Message must be at least {{ $v.message.$params.minLength.min }} letters.</span> -->
                    <span v-if="!$v.message.minLength">Your message is insufficient. Please provide more details.</span>
                  </div>
                </div>

                <!-- <div class="form-group">
                  <i class="fa fa-paperclip"></i> Attachment
                  <input type="file" ref="file" multiple="multiple" />

                  <p class="help-block"><b>File Type:</b> jpg,jpeg,png,gif,pdf,doc,docx,xls,xlsx | <b>Max file size:</b> 5MB</p>
                </div> -->
                <div class="form-group">
                  <b-icon-paperclip></b-icon-paperclip> <label for="att">Attachment(s)</label>
                  <vue-dropzone
                    id="dropzone"
                    ref="myVueDropzone"
                    :useCustomSlot="true"
                    :options="dropzoneOptions"
                    @vdropzone-error="uploadError"
                    @vdropzone-success="uploadSuccess"
                    @vdropzone-file-added="uploadFileAdded"
                  >
                    <div class="dropzone-custom-content" v-html="dropzoneOptions.myCustomContent"></div>
                  </vue-dropzone>
                </div>

                <button v-if="!spinner" class="btn btn-danger btn-lg" type="submit"><i class="far fa-paper-plane mr-1"></i> Send</button>
                <b-spinner v-if="spinner" variant="success" label="Spinning"></b-spinner>

                <!--  -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <FaqModal uuid="ccd485c9-760e-4968-ab91-8930c35c0131" /> -->
  </div>
</template>

<script>
import axios from 'axios'
import { VueEditor } from 'vue2-editor'
import { required, email, numeric, minLength, maxLength, minValue } from 'vuelidate/lib/validators'
import BackIcon from '@/components/BackIcon.vue'
import FaqModal from '@/components/FaqModal.vue'
import vue2Dropzone from 'vue2-dropzone'
import dropzoneOptions from '../helper/dropzoneOptions'

export default {
  name: 'TicketCreate',

  data() {
    return {
      spinner: false,
      spinner1: false,
      categories: [],
      subCategories: [],

      // Form data
      name: '',
      email: '',
      phone: '',
      category: '',
      message: '',

      attachments: [],

      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
    }
  },

  mounted() {
    this.spinner1 = true
    axios.get('/categories').then(res => {
      if (res.data.status == 'success') {
        this.categories = res.data.categoriesDirect
      }
      this.spinner1 = false
    })
  },

  methods: {
    handleSubmit(e) {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      let formData = new FormData()

      formData.append('email', this.email)
      formData.append('name', this.name)
      formData.append('phone', this.phone)
      formData.append('subCategoryID', this.category)
      formData.append('message', this.message)
      formData.append('source', 'web')
      formData.append('priority', 'normal')
      formData.append('originURL', window.location.origin)
      formData.append('attachments', JSON.stringify(this.attachments))

      axios
        .post('/ticket/create-general', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.$v.$reset()
            //empty form
            this.name = ''
            this.email = ''
            this.category = ''
            this.message = ''
            this.attachments = []
            this.$refs.myVueDropzone.removeAllFiles()
            // fee back
            this.$swal(res.data.title, res.data.message, 'success')
            this.$router.push({ name: 'TicketDetails', params: { ref: res.data.ticketID } })
          } else {
            this.$swal('Oops!', res.data.message, 'error')
          }
          this.spinner = false
        })
        .catch(err => {
          this.spinner = false
        })
    },

    topSelected(e) {
      if (e.target.value == 'Select...') {
        this.showSubCat = false
        return
      }
      this.subCategories = this.categories[e.target.value].subCategories
      this.showSubCat = true
      //this.category = this.categories[e.target.value].uuid
    },
    uploadFileAdded(file) {
      this.spinner = true // vdropzone-file-added( file)
    },
    uploadError(file) {
      this.spinner = false // vdropzone-file-added( file)
      this.$swal('Oops!', file.fileName + ' not uploaded.', 'error')
    },
    uploadSuccess(file, response) {
      this.spinner = false //vdropzone-success( file, response)
      this.attachments.push({
        name: response.link,
        size: file.size,
        original_name: file.name
      })
    }
  },

  computed: {
    topCategoryLabel() {
      return [] //this.$store.state.client.topCategoryLabel;
    },
    subCategoryLabel() {
      return [] //this.$store.state.client.subCategoryLabel;
    },
    dropzoneOptions() {
      return dropzoneOptions
    }
  },

  validations: {
    name: { required, minLength: minLength(3) },
    category: { required },
    message: { required, minLength: minLength(30) },
    email: { required, email },
    phone: { required, numeric, minLength: minLength(11), maxLength: maxLength(11) }
  },

  components: {
    VueEditor,
    BackIcon,
    FaqModal,
    vueDropzone: vue2Dropzone
  }
}
</script>
