<template>
  <div style="display: inline; width:100%; margin-top:2px">
    <!--
      width: 100%; height: auto; text-align: right;
     -->
    <!-- <b-icon-search v-if="!showForm"></b-icon-search> -->

    <form class="form-inline" @submit.prevent="submitHandler">
      <div class="input-group mb-2 mr-sm-2 ml-5">
        <label for="">System search</label>
        <select
          class="form-control col-form-label-sm"
          v-model="searchType"
          :class="{ 'is-invalid': $v.searchType.$error }"
          @change.prevent="selectedSearchType"
        >
          <option selected>Select...</option>
          <!-- <option value="matric">Matric</option> -->
          <option value="email">Email</option>
          <option value="ref">Reference ID</option>
          <!-- <option value="subject">Subject keyword(s)</option> -->
          <option value="category">Category</option>
          <option value="subcategory">Subcategory</option>
          <!-- <option value="studycenter">Studycenter</option>
          <option value="programme">Programme</option> -->
          <option value="daterange">Date Range</option>
          <!-- <option value="faculty">Faculty</option> -->
          <option value="agent">Agent</option>
        </select>
      </div>

      <div class="input-group mb-2 mr-sm-2" v-show="searchTermInput">
        <input v-model="searchTerm" type="text" class="form-control col-form-label-sm" :placeholder="placeholder" />
      </div>

      <!-- Date range -->
      <div class="input-group mb-2 mr-sm-2" v-show="dateRangeInput">
        <RangedatePicker @selected="selectedDateRange" i18n="EN" righttoleft="true" />
      </div>

      <!-- Categories -->
      <div class="input-group mb-2 mr-sm-2" v-show="categoryInput">
        <select class="form-control col-form-label-sm" style="width:170px" @change.prevent="selectedCategory">
          <option selected>Select...</option>
          <option v-for="category in categories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </select>
      </div>
      <!-- Subcategories -->
      <div class="input-group mb-2 mr-sm-2" v-show="subcategoryInput">
        <select class="form-control col-form-label-sm" style="width:170px" @change.prevent="selectedSubCategory">
          <option selected>Select...</option>
          <option v-for="subcategory in subcategories" :key="subcategory.id" :value="subcategory.id">
            {{ subcategory.name }}
          </option>
        </select>
      </div>
      <!-- Studycenters -->
      <div class="input-group mb-2 mr-sm-2" v-show="studycenterInput">
        <select class="form-control col-form-label-sm" style="width:170px" @change.prevent="selectedStudycenter">
          <option selected>Select...</option>
          <option v-for="item in studycenters" :key="item.id" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
      <!-- Faculties -->
      <div class="input-group mb-2 mr-sm-2" v-show="facultyInput">
        <select class="form-control col-form-label-sm" style="width:170px" @change.prevent="selectedFaculty">
          <option selected>Select...</option>
          <option v-for="item in faculties" :key="item.id" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
      <!-- Programme -->
      <div class="input-group mb-2 mr-sm-2" v-show="programmeInput">
        <select class="form-control col-form-label-sm" style="width:170px" @change.prevent="selectedProgramme">
          <option selected>Select...</option>
          <option v-for="item in programmes" :key="item.id" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>

      <!-- status -->
      <!-- <div class="input-group mb-2 mr-sm-2" v-if="userRole !== 'customer'">
        <select class="form-control col-form-label-sm" v-model="ticketStatusID">
          <option selected>Select status...</option>
          <option v-for="s in ticketStatuses" :value="s.id">{{ s.name }}</option>
        </select>
      </div> -->
      <b-spinner v-if="spinner" variant="dark" label="Spinning"></b-spinner>
      <button v-if="!spinner" type="submit" class="btn btn-sm btn-outline-dark mb-2 mr-2 ">Search</button>
      <button v-if="!spinner" type="button" class="btn btn-sm btn-outline-dark mb-2 mr-2 " @click="exportHandler" v-show="showExport">Export</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import RangedatePicker from 'vue-rangedate-picker'
import ISO8601Date from '../helper/ISO8601Date'

export default {
  name: 'SearchForm',

  props: {
    showExport: { type: Boolean, default: true }
  },

  data() {
    return {
      showForm: true,
      spinner: false,
      searchType: 'Select category...',
      searchTerm: '',
      categoryID: '',
      subcategoryID: '',
      ticketStatusID: '',
      studycenterID: '',
      facultyID: '',
      programmeID: '',
      placeholder: 'Search term...',
      // For Date range
      searchTermInput: true,
      dateRangeInput: false,
      categoryInput: false,
      subcategoryInput: false,
      studycenterInput: false,
      facultyInput: false,
      programmeInput: false,
      startDate: '',
      endDate: '',
      // for search ready
      categories: [],
      subcategories: [],
      studycenters: [],
      faculties: [],
      programmes: []
      //ticketStatuses: []
    }
  },

  /*  props: {
    userRole: { type: String, required: true }
  }, */

  mounted() {
    //this.spinner = true;
    axios.get(`/${this.userRole}/for-search`).then(res => {
      if (res.data.status == 'success') {
        this.categories = res.data.categories
        this.subcategories = res.data.subcategories
        this.studycenters = res.data.studycenters
        this.faculties = res.data.faculties
        this.programmes = res.data.programmes
        //this.ticketStatuses = res.data.ticketStatuses
      }
      this.spinner = false
    })
  },

  methods: {
    submitHandler() {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      // let formData = new FormData();
      let searchObj = {
        searchType: this.searchType,
        searchTerm: this.searchTerm,
        ticketStatusID: this.ticketStatusID,
        categoryID: this.categoryID,
        subcategoryID: this.subcategoryID,
        studycenterID: this.studycenterID,
        facultyID: this.facultyID,
        programmeID: this.programmeID,
        // For Date range
        startDate: this.startDate,
        endDate: this.endDate
      }
      let jStr = JSON.stringify(searchObj)
      window.localStorage.setItem('sobj', jStr)
      this.$emit('searchTickets', searchObj)
      this.spinner = false
      /*  axios
        .get(`/${this.userRole}/search-tickets`, {
          params: searchObj
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.$v.$reset()
            this.$emit('searchTickets', res.data)
          } else {
            this.$swal('Oops!', res.data.message, 'error')
          }
          this.spinner = false
        }) */
    },

    selectedSearchType(e) {
      this.searchTerm = ''
      /* this.searchTermInput = true
      this.dateRangeInput = false
      this.categoryInput = false
      this.studycenterInput = false */

      this.searchTermInput = true
      this.dateRangeInput = false
      this.categoryInput = false
      this.subcategoryInput = false
      this.studycenterInput = false
      this.facultyInput = false
      this.programmeInput = false

      let cat = e.target.value
      if (cat == 'Select...') {
        this.placeholder = '...'
        return
        //} else if (cat == 'matric') {
        //  this.placeholder = "Enter student's matric "
      } else if (cat == 'email') {
        this.placeholder = "Enter user's email "
      } else if (cat == 'ref') {
        this.placeholder = 'Enter reference ID'
      } else if (cat == 'subject') {
        this.placeholder = 'Enter subject here'
      } else if (cat == 'agent') {
        this.placeholder = "Enter agent's email"
      } else if (cat == 'category') {
        this.searchTermInput = false
        this.dateRangeInput = false
        this.categoryInput = true
        this.subcategoryInput = false
        this.studycenterInput = false
        this.facultyInput = false
        this.programmeInput = false
      } else if (cat == 'subcategory') {
        this.searchTermInput = false
        this.dateRangeInput = false
        this.categoryInput = false
        this.subcategoryInput = true
        this.studycenterInput = false
        this.facultyInput = false
        this.programmeInput = false
      } else if (cat == 'studycenter') {
        this.searchTermInput = false
        this.dateRangeInput = false
        this.categoryInput = false
        this.subcategoryInput = false
        this.studycenterInput = true
        this.facultyInput = false
        this.programmeInput = false
      } else if (cat == 'faculty') {
        this.searchTermInput = false
        this.dateRangeInput = false
        this.categoryInput = false
        this.subcategoryInput = false
        this.studycenterInput = false
        this.facultyInput = true
        this.programmeInput = false
      } else if (cat == 'programme') {
        this.searchTermInput = false
        this.dateRangeInput = false
        this.categoryInput = false
        this.subcategoryInput = false
        this.studycenterInput = false
        this.facultyInput = false
        this.programmeInput = true
      } else if (cat == 'daterange') {
        this.searchTermInput = false
        this.dateRangeInput = true
        this.categoryInput = false
        this.subcategoryInput = false
        this.studycenterInput = false
        this.facultyInput = false
        this.programmeInput = false
      } else {
        this.placeholder = 'Search term...'
      }
    },

    selectedDateRange(v) {
      this.startDate = ISO8601Date(v.start)
      this.endDate = ISO8601Date(v.end)
    },

    selectedCategory(e) {
      this.categoryID = e.target.value
    },

    selectedSubCategory(e) {
      this.subcategoryID = e.target.value
    },

    selectedStudycenter(e) {
      this.studycenterID = e.target.value
    },
    selectedFaculty(e) {
      this.facultyID = e.target.value
    },
    selectedProgramme(e) {
      this.programmeID = e.target.value
    },

    exportHandler() {
      this.$emit('exportHandler')
    }
  },

  validations: {
    searchType: { required }
    //searchTerm: { required }
  },

  components: { RangedatePicker },

  computed: {
    userRole() {
      return this.$store.state.authUser.Role
    }
  }
}
</script>

<style></style>
