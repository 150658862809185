<template>
  <div class="Tickets">
    <div class="row">
      <div class="col-xl-12" style="text-align: center">
        <h1 class="mb-5" style="font-size: 40px">{{ categoryName }}</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <!-- <div class="card-header">DataTable Example</div> -->
          <div class="card-body">
            <ListPublic :tickets="tickets" routeName="TicketDetails"></ListPublic>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ListPublic from '@/components/ListPublic.vue'

export default {
  name: 'Tickets',

  data() {
    return {
      tickets: [],
      categoryName: ''
    }
  },

  watch: {
    '$route.query.status': function(status, uuid) {
      this.loadTickets(status, uuid)
      window.scrollTo(0, 0)
    }
  },

  mounted() {
    this.loadTickets(this.status, this.uuid)
  },

  methods: {
    loadTickets(status) {
      axios.get(`/office-screen/tickets?status=${this.status}&uuid=${this.uuid}`).then(res => {
        if (res.data.status == 'success') {
          this.tickets = res.data.tickets
          this.categoryName = res.data.category.name
        } else {
          this.tickets = []
        }
      })
    }
  },

  components: {
    ListPublic
  },

  computed: {
    uuid() {
      let uuid = ''
      if (this.$route.query != {}) {
        uuid = this.$route.query.uuid
      }
      return uuid
    },
    status() {
      let status = ''
      if (this.$route.query != {}) {
        status = this.$route.query.status
      }
      return status
    }
  }
}
</script>
