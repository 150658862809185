<template>
  <!-- <li>
    <a class="dropdown-item" href="#" @click="logout">
      <b-icon-box-arrow-left></b-icon-box-arrow-left>
      Logout
    </a>
  </li> -->
  <div>
    <!-- Link - for profile page -->
    <a v-if="kind == 'link'" href="#" @click="logout">Logout</a>
    <!-- Iconic - for admin layout -->
    <b-icon-box-arrow-left v-if="kind == 'iconic'" title="Logout" @click="logout"></b-icon-box-arrow-left>
    <!-- List - for other layout -->
    <li v-if="kind == 'list'">
      <a class="dropdown-item" href="#" @click="logout">
        <b-icon-box-arrow-left></b-icon-box-arrow-left>
        Logout
      </a>
    </li>
    <!--  -->
    <b-dropdown-item v-if="kind == 'dd-item'" @click="logout">Logout</b-dropdown-item>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    type: '',
    kind: { required: true, type: String }
  },
  methods: {
    logout() {
      axios.post('/auth/logout').then(res => {
        if (res.data.status == 'success') {
          this.$store.state.authUser = {}
          this.$store.state.authToken = ''
          window.localStorage.removeItem('log_uuid')
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.VUE_APP_API_KEY
          this.$swal('', 'Logged out successfully.', 'info')
          this.$router.push('/login')
        }
      })
    }
  }
}
</script>

<style></style>
