import auth from '../helper/auth'
import TicketDetailsCustom from '../views/custom/TicketDetailsCustom.vue'
import ChangePasswordCustom from '../views/ChangePassword.vue'
import TicketsCustom from '../views/custom/TicketsCustom.vue'
import ProfileCustom from '../views/Profile.vue'
import CustomLayout from '../layouts/Custom.vue'

const customRoutes = [
  {
    path: '/custom',
    component: CustomLayout,
    beforeEnter: auth.ifAuthenticated,
    children: [
      {
        path: '/',
        name: 'HomeCustom',
        component: TicketsCustom
      },
      {
        path: 'tickets',
        name: 'TicketsCustom',
        component: TicketsCustom
      },
      {
        path: 'tickets/:status', // status is sticket state e.g. open, closed, escalated
        name: 'TicketsCustom2',
        component: TicketsCustom
      },
      {
        path: 'profile',
        name: 'ProfileCustom',
        component: ProfileCustom
      },

      {
        path: 'ticket/:ref',
        name: 'TicketDetailsCustom',
        component: TicketDetailsCustom
      },
      {
        path: 'change-password',
        name: 'ChangePasswordCustom',
        component: ChangePasswordCustom
      }
    ]
  }
]

export default customRoutes
