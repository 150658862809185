<template>
  <div id="KnowledgeBaseAgent" class="container">
    <div class="row">
      <div class="col-6">
        <div class="pageTitle">
          Knowledge Base
        </div>
      </div>
    </div>

    <div class="row"></div>

    <div class="row">
      <div class="col-12">
        <b-card no-body class="mb-3">
          <b-card-header header-tag="header" class="p-3" role="tab">
            <b-icon-bookshelf></b-icon-bookshelf>
            Resolutions
          </b-card-header>
          <b-card-body>
            <div>
              <input v-model="search" name="search" type="text" class="form-control form-control-lg" placeholder="search...." />
            </div>

            <!-- Knowledge base -->
            <div class="tickets">
              <div class="caption">Recent Resolutions</div>
              <div v-for="ticket in tickets" class="ticket">
                <div class="caption">Issue</div>
                <div v-html="ticket.body"></div>

                <div class="caption">Resolution</div>
                <div v-if="ticket.threads.length > 0">
                  <div v-html="ticket.threads[ticket.threads.length - 1].body"></div>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'KnowledgeBaseAgent',

  data() {
    return {
      search: '',
      tickets: []
    }
  },

  mounted() {
    let loading = this.$loading.show()
    // categories
    axios.get('/agent/knowledge-base').then(res => {
      if (res.data.status == 'success') {
        this.tickets = res.data.tickets
      }
    })

    loading.hide()
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    },
    client() {
      return this.$store.state.client
    },
    authUserRole() {
      return this.$store.state.authUser.Role
    }
  }
}
</script>

<style scoped>
/*  */
.tickets {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}

.ticket {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  border: solid 1px #ccc;
  width: 100%;
  height: auto;
}

.caption {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
</style>
