import auth from '../helper/auth'

import Layout from '../layouts/Manager.vue'
import DashboardManager from '../views/admin/Dashboard.vue'
import TicketsManager from '../views/admin/ticket/TicketsAdmin.vue'
import TicketDetailsManager from '../views/admin/ticket/TicketDetailsAdmin.vue'
import FAQManager from '../views/admin/faq/FAQAdmin.vue'
// category
import CategoriesManager from '../views/admin/category/CategoriesAdmin.vue'
import CategoryDetailsManager from '../views/admin/category/CategoryDetailsAdmin.vue'
// subCategory
import SubCategoriesManager from '../views/admin/subCategory/SubCategoriesAdmin.vue'
//customRole
import CustomRolesManager from '../views/admin/customRole/CustomRolesAdmin.vue'
import CustomRoleDetailsManager from '../views/admin/customRole/CustomRoleDetailsAdmin.vue'
// user admin
import UsersManager from '../views/admin/user/UsersAdmin.vue'
import UserDetailsManager from '../views/admin/user/UserDetailsAdmin.vue'
import StudentsManager from '../views/admin/user/StudentsAdmin.vue'
// assign agent
import AssignAgentManager from '../views/admin/assign/AssignAgentAdmin.vue'
import AssignSupervisorManager from '../views/admin/assign/AssignSupervisorAdmin.vue'
//other
import MyTicketsManager from '../views/admin/ticket/MyTicketsAdmin.vue'
import ProfileManager from '../views/Profile.vue'
import ChangePasswordManager from '../views/ChangePassword.vue'
//log
import TicketsLogManager from '../views/admin/logs/TicketsLog.vue'
import UsersLogManager from '../views/admin/logs/UsersLog.vue'

const managerRoutes = [
  {
    path: '/manager',
    component: Layout,
    beforeEnter: auth.ifAuthenticated,
    children: [
      /* core */
      {
        path: '/',
        name: 'DashboardManager',
        component: DashboardManager
      },
      {
        path: 'tickets',
        name: 'TicketsManager',
        component: TicketsManager
      },
      {
        path: 'tickets/:status',
        name: 'TicketsManager2',
        component: TicketsManager
      },
      {
        path: 'ticket/:ref',
        name: 'TicketDetailsManager',
        component: TicketDetailsManager
      },
      {
        path: 'faqs',
        name: 'FAQManager',
        component: FAQManager
      },

      /* Top Category */
      {
        path: 'categories',
        name: 'CategoriesManager',
        component: CategoriesManager
      },
      {
        path: 'category/details/:uuid',
        name: 'CategoryDetailsManager',
        component: CategoryDetailsManager
      },

      /* SubCategory */
      {
        path: 'sub-categories',
        name: 'SubCategoriesManager',
        component: SubCategoriesManager
      },

      /* Custom Role */
      {
        path: 'custom-roles',
        name: 'CustomRolesManager',
        component: CustomRolesManager
      },
      {
        path: 'custom-role/:uuid',
        name: 'CustomRoleDetailsManager',
        component: CustomRoleDetailsManager
      },

      /* User Manager */
      {
        path: 'users/:role',
        name: 'UsersManager',
        component: UsersManager
      },
      {
        path: 'user-details/:uuid',
        name: 'UserDetailsManager',
        component: UserDetailsManager
      },

      /* AssignAgent */
      {
        path: 'assign-agent/:uuid',
        name: 'AssignAgentManager',
        component: AssignAgentManager
      },
      /* AssignAgent */
      {
        path: 'assign-supervisor/:uuid',
        name: 'AssignSupervisorManager',
        component: AssignSupervisorManager
      },

      {
        path: 'my-tickets',
        name: 'MyTicketsManager',
        component: MyTicketsManager
      },

      /* Profile */
      {
        path: 'profile',
        name: 'ProfileManager',
        component: ProfileManager
      },
      {
        path: 'change-password',
        name: 'ChangePasswordManager',
        component: ChangePasswordManager
      },
      {
        path: 'students',
        name: 'StudentsManager',
        component: StudentsManager
      },
      {
        path: 'tickets-log',
        name: 'TicketsLogManager',
        component: TicketsLogManager
      },
      {
        path: 'users-log',
        name: 'UsersLogManager',
        component: UsersLogManager
      }
    ]
  }
]

export default managerRoutes
