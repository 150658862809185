<template>
  <span id="ReAssignTicketModal" style="display: inline">
    <a href="#" v-b-modal="modalID" class="btn btn-outline-info btn-sm ml-1 mr-1" title="Change Log">Re-assign</a>

    <!-- arrow-right -->
    <b-modal :id="modalID" :title="`#${ticket.ref} Re-assign ticket`" size="md" hide-footer no-close-on-backdrop @shown="modalShown">
      <div id="Re-assignTicket">
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="exampleFormControlSelect1">Select agents</label>
            <select class="form-control" id="exampleFormControlSelect1" v-model="agentUUID">
              <option>Select...</option>
              <option v-for="agent in agents" :value="agent.uuid" :key="agent.uuid">
                {{ agent.name }}
              </option>
            </select>
          </div>
          <button v-if="!spinner" class="btn btn-danger">Re-assign</button>
        </form>
        <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
      </div>
    </b-modal>
  </span>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ReAssignTicketModal',

  data() {
    return {
      spinner: false,
      modalID: 'ReAssignTicketModal',
      agents: [],
      agentUUID: ''
    }
  },

  props: {
    ticket: { type: Object },
    path: String
  },

  methods: {
    modalShown(e) {
      this.spinner = true
      axios
        .get(this.path)
        .then(res => {
          if (res.data.status == 'success') {
            this.agents = res.data.agents
            // for admin users
            if (res.data.agents == undefined) {
              this.agents = res.data.users
            }
          } else {
            this.$swal('Ooops!', res.data.message, 'error')
          }
          this.spinner = false
        })
        .catch(error => {})
    },

    handleSubmit() {
      this.spinner = true
      let formData = new FormData()
      formData.append('ref', this.ticket.ref)
      formData.append('uuid', this.agentUUID)
      axios.post('/reassigned-ticket', formData).then(res => {
        if (res.data.status == 'success') {
          this.$bvModal.hide(this.modalID)
          this.$swal('', res.data.message, 'success')
        } else {
          this.$swal('Oops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    }
    //
  },

  computed: {
    /* client() {
      return this.$store.state.client;
    }, */
  }
}
</script>

<style scoped></style>
