<template>
  <div id="app">
    <main>
      <router-view />
    </main>
    <!-- <footer class="footer" v-show="!this.$store.state.webview">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <span class="text-muted">&copy; {{ year }} NIGERIAN EDUCATION LOAN FUND. All rights reserved.</span>
          </div>
          <div class="col-6 text-right">
            <span class="text-muted">Powered by <a href="http://esupport.ng" target="_blank">eSupport</a>.</span>
          </div>
        </div>
      </div>
    </footer> -->
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('getWebview')
  },

  computed: {
    year() {
      let d = new Date()
      return d.getFullYear()
    }
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
#app {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
main {
  width: 100%;
  height: calc(100vh - 50px) !important;
  overflow-y: auto;
}
.footer {
  font-size: 0.8rem;
}
</style>
