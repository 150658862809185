<template>
  <div id="AssignSupervisor">
    <button v-b-modal="modalID" class="btn btn-xs btn-outline-secondary" title="Assign supervisor">Assign supervisor</button>

    <!-- arrow-right -->
    <b-modal :id="modalID" :title="category.name" size="md" hide-footer no-close-on-backdrop @shown="modalShown">
      <div>
        <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
        <p>Assigned supervisors</p>
        <table class="table table-hover table-bordered table-sm" style="font-size: .8rem" v-if="!spinner">
          <thead class="thead-light">
            <tr>
              <th>SN</th>
              <th>Name</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in assignedSupervisors" :key="user.uuid">
              <td>{{ index + 1 }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>
                <button @click="unassignSupervisor(user.uuid, category.uuid)" class="btn btn-outline-secondary btn-xs">unassign</button>
              </td>
            </tr>
          </tbody>
        </table>
        <!--  -->
        <p>Available supervisors</p>
        <table class="table" style="width: 100%;">
          <tr>
            <td style="width: 100%; border:none; padding:0">
              <div class="form-group">
                <select class="form-control form-control-sm" v-model="userUUID" style="width: 100%">
                  <option v-for="user in unAssignedSupervisors" :value="user.uuid">{{ user.name }} - {{ user.email }}</option>
                </select>
              </div>
            </td>
            <td style="width: 100%; border:none; padding:0">
              <button @click="assignSupervisor" class="btn btn-sm btn-secondary btn-block">Assign</button>
            </td>
          </tr>
        </table>

        <!--  -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AssignSupervisor',

  data() {
    return {
      spinner: false,
      assignedSupervisors: [],
      unAssignedSupervisors: [],
      userUUID: ''
    }
  },

  props: {
    category: { type: Object }
  },

  methods: {
    modalShown(e) {
      this.spinner = true
      axios.get(`/admin/category/supervisors/${this.category.uuid}`).then(res => {
        if (res.data.status == 'success') {
          this.assignedSupervisors = res.data.assignedSupervisors
          this.unAssignedSupervisors = res.data.unAssignedSupervisors
        } else {
          this.$swal('Ooops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    },

    assignSupervisor() {
      let formData = new FormData()
      formData.append('userUUID', this.userUUID)
      formData.append('categoryUUID', this.category.uuid)
      axios.post('admin/assign-supervisor', formData).then(res => {
        if (res.data.status == 'success') {
          this.$swal('', res.data.message, 'success')
          this.$bvModal.hide(this.modalID)
          window.location.reload()
        }
      })
    },

    unassignSupervisor(userUUID, subCategoryUUID) {
      this.$swal
        .fire({
          text: 'Please confirm un-assigning user',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, confirmed'
        })
        .then(result => {
          if (result.value) {
            /* actural action */
            let loading = this.$loading.show()
            let formData = new FormData()
            formData.append('userUUID', userUUID)
            formData.append('categoryUUID', subCategoryUUID)
            axios.post('/admin/assign-unsupervisor', formData).then(res => {
              if (res.data.status == 'success') {
                this.$swal('', res.data.message, 'success')
                window.location.reload()
              } else {
                this.$swal('', res.data.message, 'error')
              }

              loading.hide()
            })
            /* end of actural action */
          }
        })
    }
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    },
    modalID() {
      return 'assign-supervisor-' + this.category.uuid
    }
  }
}
</script>

<style scoped>
.modal-body {
}
.col {
  height: 450px;
  overflow: auto;
  background-color: #eee;
}
.secondCol {
  border-left: 3px solid #ccc;
  border-right: 3px solid #ccc;
}
.list-group {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.escalateButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
}
.list-group-item {
  font-size: 0.8rem;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #018d01;
  border-color: #008000;
}
</style>
