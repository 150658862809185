<template>
  <div id="Agent">
    <!-- <NelFundTop v-show="!this.$store.state.webview" /> -->
    <b-navbar toggleable="lg" type="light" variant="light">
      <router-link class="navbar-brand text-muted" :to="{ name: 'HomeAgent' }">
        <span><img alt="NelFund Logo" src="../assets/nelfund-icon.png" height="50"/></span>
        <span style="margin-left: 10px;">First Level</span>
      </router-link>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <li class="nav-item">
            <router-link to="/agent" class="nav-link">
              <b-icon-house></b-icon-house>
              Home
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/agent/faqs" class="nav-link">
              <b-icon-question-circle></b-icon-question-circle>
              FAQs
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link to="/agent/knowledge-base" class="nav-link">
              <b-icon-bookshelf></b-icon-bookshelf>
              Knowledge Base
            </router-link>
          </li> -->
          <li class="nav-item">
            <router-link to="/agent/tickets/open" class="nav-link">
              <b-icon-circle-fill class="text-danger"></b-icon-circle-fill>
              Open
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/agent/tickets/in_progress" class="nav-link">
              <b-icon-circle-fill class="text-primary"></b-icon-circle-fill>
              InProgress
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/agent/tickets/escalated" class="nav-link">
              <b-icon-circle-fill class="text-warning"></b-icon-circle-fill>
              Escalated
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/agent/tickets/closed" class="nav-link">
              <b-icon-circle-fill class="text-success"></b-icon-circle-fill>
              Closed
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/agent/tickets/reopened" class="nav-link">
              <b-icon-circle-fill class="text-info"></b-icon-circle-fill>
              Reopened
            </router-link>
          </li>
        </b-navbar-nav>

        <!-- Right aligned nav items -->

        <b-navbar-nav class="ml-auto">
          <!-- Notification -->
          <notifications />
          <!-- User profile -->
          <b-nav-item-dropdown right>
            <template #button-content> <b-icon-person-circle></b-icon-person-circle> {{ authUser.Name }} </template>
            <b-dropdown-item to="/agent/profile">
              <b-icon-person></b-icon-person>
              Profile
            </b-dropdown-item>
            <Logout kind="list" />
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <!--  -->
      </b-collapse>
    </b-navbar>

    <div style="margin-top: 20px; width: 100%; height: auto;">
      <router-view />
    </div>
  </div>
</template>

<script>
import NelFundTop from '@/components/NelFundTop.vue'
import Logout from '@/components/Logout.vue'
import Notifications from '../components/Notifications.vue'

export default {
  name: 'AgentLayout',

  components: {
    NelFundTop,
    Logout,
    Notifications
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    },

    year() {
      let d = new Date()
      return d.getFullYear()
    }
  }
}
</script>

<style scoped></style>
