// Student and other users

import auth from '../helper/auth'
import TicketDetailsUser from '../views/user/TicketDetailsUser.vue'
import ChangePasswordUser from '../views/ChangePassword.vue'
import TicketsUser from '../views/user/TicketsUser.vue'
import ProfileUser from '../views/Profile.vue'
import UserLayout from '../layouts/User.vue'

const userRoutes = [
  {
    path: '/user',
    component: UserLayout,
    beforeEnter: auth.ifAuthenticated,
    children: [
      {
        path: '/',
        name: 'HomeUser',
        component: TicketsUser
      },
      {
        path: 'profile',
        name: 'ProfileUser',
        component: ProfileUser
      },
      {
        path: 'tickets/:status', // status is sticket state e.g. open, closed, escalated
        name: 'TicketsUser',
        component: TicketsUser
      },
      {
        path: 'ticket/:ref',
        name: 'TicketDetailsUser',
        component: TicketDetailsUser
      },
      {
        path: 'change-password',
        name: 'ChangePasswordUser',
        component: ChangePasswordUser
      }
    ]
  }
]

export default userRoutes
