<template>
  <span>
    <button @click="deleteUser" class="btn btn-outline-secondary btn-sm ml-3">Delete</button>
  </span>
</template>

<script>
import axios from 'axios'
export default {
  name: 'DeActivateUser',

  props: { user: Object },
  methods: {
    deleteUser() {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: `DELETE: ${this.user.name}'s account`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        })
        .then(result => {
          if (result.value) {
            /* actural action */
            let loading = this.$loading.show()

            axios.post(`/admin/user/delete/${this.user.uuid}`).then(res => {
              if (res.data.status == 'success') {
                this.$swal.fire('Deleted!', `${this.user.name} deleted`, 'success')
                this.$router.push({ name: 'UsersAdmin', params: { role: this.user.role.slug } })
              } else {
                this.$swal('', res.data.message, 'error')
              }
              loading.hide()
            })
            /* end of actural action */
          }
        })
    }
  }
}
</script>

<style></style>
