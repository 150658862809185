import auth from '../helper/auth'

import Layout from '../layouts/Admin.vue'
import Dashboard from '../views/admin/Dashboard.vue'
import TicketsAdmin from '../views/admin/ticket/TicketsAdmin.vue'
import TicketsGroupAdmin from '../views/admin/ticket/TicketsGroupAdmin.vue'
import TicketDetailsAdmin from '../views/admin/ticket/TicketDetailsAdmin.vue'
import ReassignTickets from '../views/admin/ticket/ReassignTickets.vue'
import FAQAdmin from '../views/admin/faq/FAQAdmin.vue'
// category
import CategoriesAdmin from '../views/admin/category/CategoriesAdmin.vue'
import CreateCategoryAdmin from '../views/admin/category/CreateCategoryAdmin.vue'
import EditCategoryAdmin from '../views/admin/category/EditCategoryAdmin.vue'
import CategoryDetailsAdmin from '../views/admin/category/CategoryDetailsAdmin.vue'
// subCategory
import SubCategoriesAdmin from '../views/admin/subCategory/SubCategoriesAdmin.vue'
import CreateSubCategoryAdmin from '../views/admin/subCategory/CreateSubCategoryAdmin.vue'
import EditSubCategoryAdmin from '../views/admin/subCategory/EditSubCategoryAdmin.vue'
//customRole
import CustomRolesAdmin from '../views/admin/customRole/CustomRolesAdmin.vue'
import CreateCustomRoleAdmin from '../views/admin/customRole/CreateCustomRoleAdmin.vue'
import CustomRoleDetailsAdmin from '../views/admin/customRole/CustomRoleDetailsAdmin.vue'
import EditCustomRoleAdmin from '../views/admin/customRole/EditCustomRoleAdmin.vue'
// user admin
import UsersAdmin from '../views/admin/user/UsersAdmin.vue'
import CreateUserAdmin from '../views/admin/user/CreateUserAdmin.vue'
import EditUserAdmin from '../views/admin/user/EditUserAdmin.vue'
import UserDetailsAdmin from '../views/admin/user/UserDetailsAdmin.vue'
import StudentsAdmin from '../views/admin/user/StudentsAdmin.vue'
import StudentsUploadAdmin from '../views/admin/user/StudentsUploadAdmin.vue'
// assign agent
import AssignAgentAdmin from '../views/admin/assign/AssignAgentAdmin.vue'
import AssignSupervisorAdmin from '../views/admin/assign/AssignSupervisorAdmin.vue'
//other
import MyTicketsAdmin from '../views/admin/ticket/MyTicketsAdmin.vue'
import ProfileAdmin from '../views/Profile.vue'
import ChangePasswordAdmin from '../views/ChangePassword.vue'
//log
import TicketsLog from '../views/admin/logs/TicketsLog.vue'
import UsersLog from '../views/admin/logs/UsersLog.vue'

const adminRoutes = [
  {
    path: '/admin',
    //component: () => import('../layouts/Admin.vue'),
    component: Layout,
    beforeEnter: auth.ifAuthenticated,
    children: [
      /* core */
      {
        path: '/',
        name: 'Dashboard',
        //component: () => import('../views/admin/Dashboard.vue')
        component: Dashboard
      },
      {
        path: 'tickets',
        name: 'TicketsAdmin',
        component: TicketsAdmin
      },
      {
        path: 'tickets/:status',
        name: 'TicketsAdmin2',
        component: TicketsAdmin
      },
      {
        path: 'tickets-group/:group',
        name: 'TicketsGroupAdmin',
        component: TicketsGroupAdmin
      },
      {
        path: 'ticket/:ref',
        name: 'TicketDetailsAdmin',
        //component: () => import('../views/admin/ticket/TicketDetailsAdmin.vue')
        component: TicketDetailsAdmin
      },
      {
        path: 'reassign-tickets',
        name: 'ReassignTickets',
        component: ReassignTickets
      },
      /* {
            path: 'create',
            name: 'CreateTicketAdmin',
            component: () => import('../views/admin/ticket/CreateTicketAdmin.vue')
        }, */
      {
        path: 'faqs',
        name: 'FAQAdmin',
        // component: () => import('../views/admin/faq/FAQAdmin.vue')
        component: FAQAdmin
      },
      /* Environment */

      /* Top Category */
      {
        path: 'categories',
        name: 'CategoriesAdmin',
        //component: () => import('../views/admin/category/CategoriesAdmin.vue')
        component: CategoriesAdmin
      },
      {
        path: 'category/create',
        name: 'CreateCategoryAdmin',
        //component: () => import('../views/admin/category/CreateCategoryAdmin.vue')
        component: CreateCategoryAdmin
      },
      {
        path: 'category/edit/:uuid',
        name: 'EditCategoryAdmin',
        //component: () => import('../views/admin/category/EditCategoryAdmin.vue')
        component: EditCategoryAdmin
      },
      {
        path: 'category/details/:uuid',
        name: 'CategoryDetailsAdmin',
        component: CategoryDetailsAdmin
      },

      /* SubCategory */
      {
        path: 'sub-categories',
        name: 'SubCategoriesAdmin',
        //component: () => import('../views/admin/subCategory/SubCategoriesAdmin.vue')
        component: SubCategoriesAdmin
      },
      {
        path: 'sub-category/create',
        name: 'CreateSubCategoryAdmin',
        //component: () => import('../views/admin/subCategory/CreateSubCategoryAdmin.vue')
        component: CreateSubCategoryAdmin
      },
      {
        path: 'sub-category/edit/:uuid',
        name: 'EditSubCategoryAdmin',
        //component: () => import('../views/admin/subCategory/EditSubCategoryAdmin.vue')
        component: EditSubCategoryAdmin
      },

      /* Custom Role */
      {
        path: 'custom-roles',
        name: 'CustomRolesAdmin',
        //component: () => import('../views/admin/customRole/CustomRolesAdmin.vue')
        component: CustomRolesAdmin
      },
      {
        path: 'custom-role/create',
        name: 'CreateCustomRoleAdmin',
        //component: () => import('../views/admin/customRole/CreateCustomRoleAdmin.vue')
        component: CreateCustomRoleAdmin
      },
      {
        path: 'custom-role/:uuid',
        name: 'CustomRoleDetailsAdmin',
        //component: () => import('../views/admin/customRole/CustomRoleDetailsAdmin.vue')
        component: CustomRoleDetailsAdmin
      },
      {
        path: 'custom-role/edit/:uuid',
        name: 'EditCustomRoleAdmin',
        //component: () => import('../views/admin/customRole/EditCustomRoleAdmin.vue')
        component: EditCustomRoleAdmin
      },

      /* User Admin */
      {
        path: 'users/:role',
        name: 'UsersAdmin',
        //component: () => import('../views/admin/user/UsersAdmin.vue')
        component: UsersAdmin
      },
      {
        path: 'user/create',
        name: 'CreateUserAdmin',
        //component: () => import('../views/admin/user/CreateUserAdmin.vue')
        component: CreateUserAdmin
      },
      {
        path: 'user/edit/:uuid',
        name: 'EditUserAdmin',
        //component: () => import('../views/admin/user/EditUserAdmin.vue')
        component: EditUserAdmin
      },
      {
        path: 'user-details/:uuid',
        name: 'UserDetailsAdmin',
        component: UserDetailsAdmin
      },

      /* AssignAgent */
      {
        path: 'assign-agent/:uuid',
        name: 'AssignAgentAdmin',
        //component: () => import('../views/admin/assign/AssignAgentAdmin.vue')
        component: AssignAgentAdmin
      },
      /* AssignAgent */
      {
        path: 'assign-supervisor/:uuid',
        name: 'AssignSupervisorAdmin',
        //component: () => import('../views/admin/assign/AssignSupervisorAdmin.vue')
        component: AssignSupervisorAdmin
      },

      /* Billing */
      /*  {
             path: 'billing/transactions',
             name: 'Transactions',
             component: () => import('../views/admin/billing/Transactions.vue')
         },
         {
             path: 'billing/payment-method',
             name: 'PaymentMethod',
             component: () => import('../views/admin/billing/PaymentMethod.vue')
         },
         {
             path: 'billing/renew',
             name: 'Renew',
             component: () => import('../views/admin/billing/Renew.vue')
         }, */

      {
        path: 'my-tickets',
        name: 'MyTicketsAdmin',
        //component: () => import('../views/admin/ticket/MyTicketsAdmin.vue')
        component: MyTicketsAdmin
      },

      /* Profile */
      /* {
            path: 'profile',
            name: 'UserProfileAdmin',
            //component: () => import('../views/Profile.vue')
            component: UserProfileAdmin
        }, */

      {
        path: 'profile',
        name: 'ProfileAdmin',
        component: ProfileAdmin
      },
      {
        path: 'change-password',
        name: 'ChangePasswordAdmin',
        component: ChangePasswordAdmin
      },
      {
        path: 'students',
        name: 'StudentsAdmin',
        component: StudentsAdmin
      },
      {
        path: 'students-upload',
        name: 'StudentsUploadAdmin',
        component: StudentsUploadAdmin
      },
      {
        path: 'tickets-log',
        name: 'TicketsLog',
        component: TicketsLog
      },
      {
        path: 'users-log',
        name: 'UsersLog',
        component: UsersLog
      }
    ]
  }
]

export default adminRoutes
