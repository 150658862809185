<template>
  <div id="OfficeScreen">
    <div class="container-fluid">
      <div class="wrapper">
        <img alt="NelFund Logo" src="../assets/nelfund-logo.png" class="logo mt-1 mb-2" style="height: 50px" />

        <!-- <h1 class="mb-1">{{ client.name }}</h1> -->
        <div class=" font-weight-bold">Ticketing Summary Reports</div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'OfficeScreenLayout',

  data() {
    return {
      sideMenuDisplayStatus: true
    }
  },

  mounted() {
    //this.$store.dispatch("getClient");
  },

  computed: {
    client() {
      let client = this.$store.state.client
      if (client.name == '') {
        this.$router.push({ name: 'AccountNotFound' })
      }
      return client
    },

    copyright() {
      let d = new Date()
      return `Copyright ©${d.getFullYear()} ${this.$store.state.client.shortName}`
    }
  },

  methods: {},

  components: {}
}
</script>

<style scoped>
/* @import "https://cdn.jsdelivr.net/npm/animate.css@3.5.1";
.page-header-subtitle {
  margin-left: 10px !important;
} */
/* #layoutSidenav #layoutSidenav_content {
  margin-left: 0;
} */

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
