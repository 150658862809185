import { render, staticRenderFns } from "./ReAssignTicketModal.vue?vue&type=template&id=6b313b57&scoped=true&"
import script from "./ReAssignTicketModal.vue?vue&type=script&lang=js&"
export * from "./ReAssignTicketModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b313b57",
  null
  
)

export default component.exports