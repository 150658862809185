<template>
  <div id="CategoryModal" style="display: inline">
    <a href="#" v-b-modal="modalID" title="Change Category">Category</a>

    <!-- arrow-right -->
    <b-modal :id="modalID" title="Change Category" size="sm" hide-footer no-close-on-backdrop @shown="loadCategories">
      <form @submit.prevent="submit">
        <!-- categories -->
        <div class="form-group">
          <label for="category">Category*</label>
          <select @change="topSelected($event)" class="form-control" :class="{ 'is-invalid': $v.categoryUUID.$error, 'is-valid': !$v.categoryUUID.$invalid }">
            <option value="Select..." selected>Select...</option>
            <option v-for="(item, index) in categories" :key="index" :value="index">{{ item.name }}</option>
          </select>
          <div class="invalid-feedback">
            <span v-if="!$v.categoryUUID.required">Category of issues is required.</span>
          </div>
        </div>

        <!-- subcategories -->
        <div class="form-group">
          <label for="subcategory">Subcategory*</label>
          <select @change="subSelected($event)" class="form-control" :class="{ 'is-invalid': $v.subcategoryUUID.$error, 'is-valid': !$v.subcategoryUUID.$invalid }">
            <option value="Select..." selected>Select...</option>
            <option v-for="(item, index) in subCategories" :key="index" :value="index">{{ item.name }}</option>
          </select>
          <div class="invalid-feedback">
            <span v-if="!$v.subcategoryUUID.required">Subcategory of issues is required.</span>
          </div>
        </div>

        <!-- agents -->
        <div class="form-group">
          <label for="subcategory">Reassigned To</label>
          <select class="form-control" v-model="reAssignedToUUID">
            <option value="Select..." selected>Select...</option>
            <option v-for="(item, index) in users" :key="index" :value="item.uuid">{{ item.name }}</option>
          </select>
        </div>

        <button v-if="!spinner" class="btn btn-warning" type="submit"><i class="far fa-paper-plane mr-1"></i> Submit</button>
        <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CategoryModal',

  data() {
    return {
      spinner: false,
      modalID: 'CategoryModal',
      categoryUUID: '',
      subcategoryUUID: '',
      reAssignedToUUID: '',
      topIndex: 0,

      categories: [],
      subCategories: [],
      users: []
    }
  },

  props: {
    ticket: { type: Object },
    kind: String
  },

  mounted() {},

  methods: {
    loadCategories() {
      this.spinner = true
      axios.get('/categories').then(res => {
        if (res.data.status == 'success') {
          this.categories = res.data.categoriesDirect
        }
        this.spinner = false
      })
    },

    topSelected(e) {
      const index = e.target.value
      if (index == 'Select...') {
        return
      }
      this.topIndex = index

      this.categoryUUID = this.categories[index].uuid
      this.subCategories = this.categories[index].subCategories
    },

    subSelected(e) {
      const index = e.target.value
      if (index == 'Select...') {
        return
      }
      this.subcategoryUUID = this.categories[this.topIndex].subCategories[index].uuid // set uuid
      this.users = this.categories[this.topIndex].subCategories[index].users // set users
    },

    submit() {
      this.spinner = true

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      let formData = new FormData()
      formData.append('subcategoryUUID', this.subcategoryUUID)
      formData.append('categoryUUID', this.categoryUUID)
      formData.append('userUUID', this.reAssignedToUUID)
      formData.append('ref', this.ticket.ref)

      axios.post('/change-category', formData).then(res => {
        if (res.data.status == 'success') {
          this.$swal('Category', res.data.message, 'success')
          this.$emit('categoryChangeSuccess', res.data.cat)
          this.$bvModal.hide(this.modalID)
        } else {
          this.$swal('Ooops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    }
  },

  computed: {
    client() {
      return this.$store.state.client
    }
  },

  validations: {
    categoryUUID: { required },
    subcategoryUUID: { required }
  }
}
</script>

<style scoped>
.modal-body {
}
.col {
  height: 450px;
  overflow: auto;
  background-color: #eee;
}
.secondCol {
  border-left: 3px solid #ccc;
  border-right: 3px solid #ccc;
}
.list-group {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.escalateButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
}
.list-group-item {
  font-size: 0.8rem;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #4eadea;
  border-color: #4eadea;
}
</style>
