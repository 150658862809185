<template>
  <tr v-if="name != 'Open'">
    <th>{{ name }} Date</th>
    <td>{{ date | formatUnixDateTime }}</td>
  </tr>
</template>

<script>
export default {
  props: {
    ticket: Object
  },

  data() {
    return {
      name: '',
      date: ''
    }
  },

  mounted() {
    let status = this.ticket.status.slug
    this.name = this.ticket.status.name
    if (status == 'escalated') {
      this.date = this.ticket.escalatedAt
    } else if (status == 'closed') {
      this.date = this.ticket.closedAt
    } else if (status == 'reopened') {
      this.date = this.ticket.reOpenedAt
    }
  }
}
</script>

<style></style>
