<template>
  <div id="EditSubItem">
    <button v-b-modal="customRoleItem.uuid + '-edit'" class="btn btn-xs btn-outline-secondary" title="Edit">Edit</button>

    <b-modal centered :id="customRoleItem.uuid + '-edit'" size="md" hide-footer no-close-on-backdrop>
      <template #modal-title> Edit </template>
      <div class="">
        <form @submit.prevent="updateCustomRoleItem">
          <div class="form-group">
            <input type="text" v-model="customRoleItem.name" class="form-control" />
          </div>

          <button class="btn btn-success btn-block">Update</button>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'EditSubItem',

  props: {
    customRoleItem: { type: Object }
  },

  methods: {
    updateCustomRoleItem() {
      let loading = this.$loading.show()

      let formData = new FormData()
      formData.append('name', this.customRoleItem.name)
      formData.append('uuid', this.customRoleItem.uuid)
      axios.post('admin/custom-role-item/edit', formData).then(res => {
        if (res.data.status == 'success') {
          this.$swal('', `${this.customRoleItem.name} updated successfully`, 'success')
          window.location.reload()
        } else {
          this.$swal('', res.data.message, 'error')
        }
        loading.hide()
      })
    },

    closeModal() {
      this.$emit('closeModal')
    }
  },

  computed: {
    client() {
      return this.$store.state.client
    }
  },

  created() {
    //this.$store.commit("SET_LAYOUT", "agent-layout");
  }
}
</script>
