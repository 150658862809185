<template>
  <div id="CreateUserAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Upload</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">New students upload</div>
          <div class="card-body">
            <div v-if="preview">
              <h1>Data Preview</h1>
              <p class="text-success">Total records: {{ students.length }}</p>
              <p class="text-danger">Please verify the accuracy of the data, and then click the save button at the bottom.</p>
            </div>
            <p v-else class="text-danger">
              ONLY CSV IS ALLOWED. Please make sure it follows the screenshot's instructions for <b>column arrangement</b>.
            </p>

            <table class="table table-sm table-bordered monofont">
              <thead>
                <tr>
                  <th>matric</th>
                  <th>name</th>
                  <th>email</th>
                  <th>mobile</th>
                  <th>departmentID</th>
                  <th>facultyID</th>
                  <th>programmeID</th>
                  <th>dateofbirth</th>
                  <th>gender</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(s, i) in students" :key="i">
                  <td>{{ s.Matric }}</td>
                  <td>{{ s.Name }}</td>
                  <td>{{ s.Email }}</td>
                  <td>{{ s.Mobile }}</td>
                  <td>{{ s.CentreID }}</td>
                  <td>{{ s.FacultyID }}</td>
                  <td>{{ s.ProgrammeID }}</td>
                  <td>{{ s.DateOfBirth }}</td>
                  <td>{{ s.Gender }}</td>
                </tr>
              </tbody>
            </table>

            <p v-if="preview">
              <button @click="save" class="btn btn-danger btn-lg">Yes, save</button>
            </p>

            <!-- Dropzone -->
            <div class="form-group mt-5 mb-5" v-if="!preview">
              <b-icon-card-list></b-icon-card-list> <label for="att">upload csv file</label>
              <vue-dropzone
                id="dropzone"
                ref="myVueDropzone"
                :useCustomSlot="true"
                :options="dropzoneOptions"
                @vdropzone-error="uploadError"
                @vdropzone-success="uploadSuccess"
                @vdropzone-file-added="uploadFileAdded"
              >
                <div class="dropzone-custom-content" v-html="dropzoneOptions.myCustomContent"></div>
              </vue-dropzone>
            </div>
            <!-- Dropzone -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import vue2Dropzone from 'vue2-dropzone'
//import { required, email, numeric, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'StudentsUploadAdmin',

  data() {
    return {
      spinner: false,
      preview: false,
      fileName: '',
      students: [],
      studentsDefault: [
        {
          Matric: 'CSC...',
          Name: 'John...',
          Email: 'fut...@nelfund.edu.ng',
          Mobile: '0803...',
          CentreID: 'LA01',
          FacultyID: 'SAAT',
          ProgrammeID: 'P040206',
          DateOfBirth: '1982-10-25',
          Gender: 'M'
        },
        {
          Matric: 'CSC...',
          Name: 'Mary...',
          Email: 'mary...@nelfund.edu.ng',
          Mobile: '0706...',
          CentreID: 'EN02',
          FacultyID: 'SAAT',
          ProgrammeID: 'P040101',
          DateOfBirth: '1972-06-12',
          Gender: 'F'
        }
      ]
    }
  },

  mounted() {
    this.students = this.studentsDefault
  },

  methods: {
    uploadError(file) {
      this.spinner = false
      this.preview = false
      this.students = this.studentsDefault
      this.$swal('Oops!', file.fileName + ' not uploaded.', 'error')
    },

    uploadSuccess(file, response) {
      this.preview = true
      this.fileName = response.fileName
      this.students = response.students
      this.spinner = false
      //console.log(this.fileName)
      //console.log(this.students)
    },

    uploadFileAdded(file) {
      this.spinner = true
    },

    save() {
      this.spinner = true
      axios
        .post(`/admin/students-upload?fileName=${this.fileName}`)
        .then(res => {
          this.$swal('Success!', res.data.message, 'success')
          alert(res.data.message)
          console.log(res.data.numberOfFailed)
          window.location.reload()
        })
        .catch(error => {
          this.$swal('Oops!', 'Something went wrong.', 'error')
          window.location.reload()
        })
    }
  },

  computed: {
    dropzoneOptions() {
      return {
        thumbnailHeight: 80,
        maxFilesize: 2 ** 22, // 1073741824 bytes  1GB
        url: process.env.VUE_APP_API_URL + '/admin/students-upload',
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
          Authorization: 'Bearer ' + this.$store.state.authToken
        },
        acceptedFiles: 'text/csv',
        myCustomContent: '<span class="vdContent-title">Drag and drop to upload files here!</span>'
      }
    },

    client() {
      return this.$store.state.client
    }
  },

  components: {
    vueDropzone: vue2Dropzone
  }
}
</script>

<style scoped>
.monofont {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size: 0.85rem;
}
</style>
