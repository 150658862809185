<template>
  <b-modal id="FaqModal" :title="faq.question" size="md" hide-footer no-close-on-backdrop>
    <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
    <div v-else>
      <div v-html="faq.answer"></div>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios'
export default {
  name: 'FaqModal',
  props: {
    uuid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      faq: {},
      spinner: false
    }
  },
  mounted() {
    this.spinner = true
    this.$bvModal.show('FaqModal')
    axios
      .get('/faq/' + this.uuid)
      .then(res => {
        if (res.data.status == 'success') {
          this.faq = res.data.faq
        } else {
          this.$swal('Ooops!', res.data.message, 'error')
        }
        this.spinner = false
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    open() {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
    }
  }
}
</script>

<style>
.modal-body ol,
.modal-body ul {
  padding: 0 1rem !important;
}
</style>
