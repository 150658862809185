import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//axios
import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common['apikey'] = process.env.VUE_APP_API_KEY
axios.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.VUE_APP_API_KEY

// Import Bootstrap an BootstrapVue CSS files (order is important)
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/general.css'
import './assets/admin.css'
import './assets/timeline.css'
import './assets/editor.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Sweetalert2 // 12kb + 626 KB
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css' // 41 KB
Vue.use(VueSweetalert2)

// Validate 132kb
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// Ratings
import StarRating from 'vue-star-rating'
Vue.component('star-rating', StarRating)

// VueGoodTablePlugin ..
import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin)

// vue-loading-overlay // 12kb
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css' // 503 bytes
Vue.use(Loading, {
  color: '#824D79',
  loader: 'dots',
  canCancel: true
})

// vue2Dropzone
//import vue2Dropzone from "vue2-dropzone";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

// filter
import './helper/filters'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
