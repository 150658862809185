<template>
  <div id="EscalateModal">
    <button v-b-modal="modalID" class="btn btn-sm btn-warning ml-1 mr-1" title="Escalate">Escalate</button>

    <!-- arrow-right -->
    <b-modal :id="modalID" title="Escalate" :size="modalSize" hide-footer no-close-on-backdrop @shown="loadData">
      <!-- Agent - only agent can escalate to second level -->
      <div v-if="authUser.Role == 'agent'">
        <button class="btn btn-danger btn-sm btn-block mt-3 mb-3" @click="escalateToSupervisor">Second Level Escalate</button>
        <!-- <p>Or escalate to others</p> -->
      </div>

      <div class="row" v-if="authUser.Role == 'supervisor'">
        <!-- 1st Column -->
        <div class="col">
          <div class="list-group list-group-flush">
            <button type="button" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" v-for="(item, index) in customRoles" :key="index" @click="addSubItem(index)" :class="topIndex == index ? 'active' : ''">
              {{ item.name }}
              <b-icon-arrow-right></b-icon-arrow-right>
              <!-- <span class="badge badge-primary badge-pill">2</span> -->
            </button>
          </div>
        </div>

        <!-- 2nd Colunm -->
        <div class="col secondCol">
          <div class="list-group list-group-flush">
            <button type="button" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" v-for="(item, index) in subItems" :key="index" @click="showEscalateButton(index)" :class="subIndex == index ? 'active' : ''">
              {{ item.name }}
              <b-icon-arrow-right></b-icon-arrow-right>
            </button>
          </div>
        </div>

        <!-- 3rd Column -->
        <div class="col escalateButtonWrapper">
          <div v-if="subIndex > -1">
            <!-- <p>
              Escalate ticket to <br />
              <b>{{ subItemObj.name }}</b>
            </p>
            <button v-if="!spinner" class="btn btn-danger" @click="escalateToCustom()">Escalate</button>
            <b-spinner v-if="spinner" variant="success" label="Spinning"></b-spinner> -->
            <div class="list-group list-group-flush">
              <button type="button" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" v-for="u in subItemObj.users" @click="escalateToCustom(u.uuid)">
                <span class="mr-2">Escalate to</span> <b class="text-right">{{ u.name }}</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'EscalateModal',

  data() {
    return {
      spinner: false,
      modalID: 'escalated',
      topIndex: -1,
      topObj: {},
      subItems: [],
      subIndex: -1,
      subItemObj: {},
      customRoles: [],
      modalSize: 'lg'
    }
  },

  props: {
    ticket: { type: Object }
  },

  mounted() {
    if (this.authUser.Role == 'agent') {
      this.modalSize = 'sm'
    }
  },

  methods: {
    loadData() {
      axios.get(`/${this.authUser.Role}/custom-roles`).then(res => {
        if (res.data.status == 'success') {
          this.customRoles = res.data.customRoles
        } else {
          this.$swal('Ooops!', res.data.message, 'error')
          //this.$router.push({ name: "Home" });
        }
      })
    },

    escalateToCustom(userUUID) {
      //console.log(userUUID)
      //return

      let formData = new FormData()
      formData.append('ticketRef', this.ticket.ref)
      //formData.append('userEmail', this.authUser.Email)
      formData.append('userUUID', userUUID)
      formData.append('customRoleUUID', this.topObj.uuid)
      formData.append('customRoleItemUUID', this.subItemObj.uuid)

      this.spinner = true
      axios.post(`/${this.authUser.Role}/custom-escalate`, formData).then(res => {
        if (res.data.status == 'success') {
          this.$swal('Escalated!', res.data.message, 'success')
          //this.$emit('customEscalateSuccess', res.data.newStatus)
          this.$bvModal.hide(this.modalID)
          window.location.reload()
        } else {
          this.$swal('Ooops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    },

    escalateToSupervisor() {
      let loading = this.$loading.show()
      let formData = new FormData()
      formData.append('ref', this.ticket.ref)
      formData.append('status', 'escalate')
      formData.append('originURL', window.location.origin)
      axios.post(`/${this.authUser.Role}/change-ticket-status`, formData).then(res => {
        if (res.data.status == 'success') {
          this.ticket.status = res.data.newStatus
          //this.showEscalateModal = false;
          loading.hide()
          this.$swal('Done!', res.data.message, 'success')
          window.location.reload()
        }
      })
    },

    addSubItem(topIndex) {
      this.topIndex = topIndex
      this.subItems = this.customRoles[topIndex].items
      this.topObj = this.customRoles[topIndex]
      // just for reset
      this.subIndex = -1
      this.subItemObj = {}
    },

    showEscalateButton(subIndex) {
      this.subIndex = subIndex
      this.subItemObj = this.subItems[subIndex]
    },

    closeModal() {
      this.$emit('closeModal')
    }
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    }
  }
}
</script>

<style scoped>
.modal-body {
}
.col {
  height: 450px;
  overflow: auto;
  background-color: #eee;
}
.secondCol {
  border-left: 3px solid #ccc;
  border-right: 3px solid #ccc;
}
.list-group {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

/* .escalateButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
} */
.list-group-item {
  font-size: 0.8rem;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #018d01;
  border-color: #008000;
}
</style>
