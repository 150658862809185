<template>
  <div id="AssignAgent">
    <button v-b-modal="modalID" class="btn btn-xs btn-outline-secondary" title="Assign agent">Assign agent</button>

    <!-- arrow-right -->
    <b-modal :id="modalID" :title="subCategory.name" size="md" hide-footer no-close-on-backdrop @shown="modalShown">
      <div>
        <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
        <p>Assigned agents</p>
        <table class="table table-hover table-bordered table-sm" style="font-size: .8rem" v-if="!spinner">
          <thead class="thead-light">
            <tr>
              <th>SN</th>
              <th>Name</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in assignedAgents" :key="user.uuid">
              <td>{{ index + 1 }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>
                <button @click="unassignAgent(user.uuid, subCategory.uuid)" class="btn btn-outline-secondary btn-xs">unassign</button>
              </td>
            </tr>
          </tbody>
        </table>
        <!--  -->
        <p>Available agents</p>
        <table class="table" style="width: 100%;">
          <tr>
            <td style="width: 100%; border:none; padding:0">
              <div class="form-group">
                <select class="form-control form-control-sm" v-model="userUUID" style="width: 100%">
                  <option v-for="user in unAssignedAgents" :value="user.uuid">{{ user.name }} - {{ user.email }}</option>
                </select>
              </div>
            </td>
            <td style="width: 100%; border:none; padding:0">
              <button @click="assignAgent" class="btn btn-sm btn-secondary btn-block">Assign</button>
            </td>
          </tr>
        </table>

        <!--  -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AssignAgent',

  data() {
    return {
      spinner: false,
      assignedAgents: [],
      unAssignedAgents: [],
      userUUID: ''
    }
  },

  props: {
    subCategory: { type: Object }
  },

  methods: {
    modalShown(e) {
      this.spinner = true
      axios.get(`/admin/sub-category/agents/${this.subCategory.uuid}`).then(res => {
        if (res.data.status == 'success') {
          this.assignedAgents = res.data.assignedAgents
          this.unAssignedAgents = res.data.unAssignedAgents
        } else {
          this.$swal('Ooops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    },

    assignAgent() {
      let formData = new FormData()
      formData.append('userUUID', this.userUUID)
      formData.append('subCategoryUUID', this.subCategory.uuid)
      axios.post('admin/assign-agent-single', formData).then(res => {
        if (res.data.status == 'success') {
          this.$swal('', res.data.message, 'success')
          this.$bvModal.hide(this.modalID)
          window.location.reload()
        }
      })
    },

    unassignAgent(userUUID, subCategoryUUID) {
      this.$swal
        .fire({
          text: 'Please confirm un-assigning user',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, confirmed'
        })
        .then(result => {
          if (result.value) {
            /* actural action */
            let loading = this.$loading.show()
            let formData = new FormData()
            formData.append('userUUID', userUUID)
            formData.append('categoryUUID', subCategoryUUID)
            axios.post('/admin/assign-unagent', formData).then(res => {
              if (res.data.status == 'success') {
                this.$swal('', res.data.message, 'success')
                window.location.reload()
              } else {
                this.$swal('', res.data.message, 'error')
              }

              loading.hide()
            })
            /* end of actural action */
          }
        })
    }
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    },
    modalID() {
      return 'assign-agent-' + this.subCategory.uuid
    }
  }
}
</script>
