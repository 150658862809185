<template>
  <div id="TicketsLog" class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">TicketsLog ({{ reports.length }})</div>
          <div class="card-body">
            <div v-if="spinner" class="text-center">
              <b-spinner variant="warning" label="Spinning"></b-spinner>
            </div>
            <vue-good-table
              v-if="!spinner"
              :columns="columns"
              :rows="reports"
              :line-numbers="true"
              :pagination-options="{
                enabled: true,
                perPage: 50
              }"
              :search-options="{
                enabled: true,
                trigger: 'enter'
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: [{ field: 'Date', type: 'desc' }]
              }"
              styleClass="vgt-table condensed myGoodTable"
            >
              <div slot="table-actions">
                <div class="input-group mb-2 mr-sm-2">
                  <label class="mt-2 ml-5">Date Range:</label>
                  <RangedatePicker @selected="selectedDateRange" i18n="EN" class="mr-5" righttoleft="true" />
                  <a :href="downloadLink" class="btn btn-sm btn-secondary ml-4">Download</a>
                </div>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import RangedatePicker from 'vue-rangedate-picker'
import ISO8601Date from '../../../helper/ISO8601Date'

export default {
  name: 'TicketsLog',

  components: {
    RangedatePicker
  },

  data() {
    return {
      reports: [],
      name: '',
      description: '',
      spinner: false,
      startDate: '',
      endDate: '',
      downloadLink: ''
    }
  },

  mounted() {
    this.loadData()
  },

  methods: {
    loadData() {
      this.spinner = true
      axios.get(`/admin/tickets-log?start=${this.startDate}&end=${this.endDate}`).then(res => {
        if (res.data.status == 'success') {
          this.reports = res.data.reports
          this.downloadLink = res.data.downloadLink
        }
        this.spinner = false
      })
    },

    selectedDateRange(v) {
      this.startDate = ISO8601Date(v.start)
      this.endDate = ISO8601Date(v.end)
      this.loadData()
    }
  },

  computed: {
    columns() {
      let cols = [
        {
          label: 'DATE',
          field: 'Date',
          sortable: true
        },
        {
          label: 'Ticket ID',
          field: 'TicketRef',
          sortable: true
        },
        {
          label: 'Ticket Status',
          field: 'TicketStatus',
          sortable: true
        },
        /* {
          label: 'Department Name',
          field: 'DepartmentName',
          sortable: true
        }, */
        {
          label: 'User Name',
          field: 'UserName',
          sortable: true
        },
        {
          label: 'Comment',
          field: 'Comment',
          sortable: false
        },
        {
          label: 'Ref',
          field: 'ID',
          sortable: false
        }
      ]
      return cols
    },
    authUserRole() {
      return this.$store.state.authUser.Role
    }
  }
}
</script>

<style></style>
