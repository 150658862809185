<template>
  <div id="Create">
    <button v-b-modal="'create-subItem'" class="btn btn-sm btn-success mr-3" title="Create">Create</button>

    <b-modal centered id="create-subItem" size="md" hide-footer no-close-on-backdrop>
      <template #modal-title> Create </template>
      <div class="">
        <form @submit.prevent="createCustomRoleItem" class="mt-4">
          <div class="form-group">
            <label for=""><b>Add new item </b></label>
            <input type="text" v-model="itemFormName" class="form-control" :class="{ 'is-invalid': $v.itemFormName.$error, 'is-valid': !$v.itemFormName.$invalid }" placeholder="Name" />
            <div class="invalid-feedback">
              <span v-if="!$v.itemFormName.required">Name is required.</span>
              <span v-if="!$v.itemFormName.minLength">Name must be at least {{ $v.itemFormName.$params.minLength.min }} letters.</span>
            </div>
          </div>
          <button class="btn btn-danger">Submit</button>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { required, minLength } from 'vuelidate/lib/validators'
export default {
  name: 'CreateSubItem',

  data() {
    return {
      itemFormName: '',
      customRoleItem: {}
    }
  },

  methods: {
    createCustomRoleItem() {
      let loading = this.$loading.show()
      this.$v.$touch()
      if (this.$v.$invalid) {
        loading.hide()
        return
      }
      let formData = new FormData()
      formData.append('name', this.itemFormName)
      formData.append('customRoleUUID', this.$route.params.uuid)
      axios.post('admin/custom-role-item/create', formData).then(res => {
        if (res.data.status == 'success') {
          //console.log(res.data.customRoleItem)
          //this.items.push(res.data.customRoleItem)
          this.$swal('', `${this.itemFormName} created successfully`, 'success')
          //this.itemFormName = ''
          this.$v.$reset()
          window.location.reload()
        } else {
          this.$swal('', res.data.message, 'error')
        }
        loading.hide()
      })
    }
  },

  validations: {
    itemFormName: { required, minLength: minLength(2) }
  }
}
</script>
