<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-header"><BackIcon /> <b-icon-stickies></b-icon-stickies> {{ label }} {{ totalTickets }}</div>
          <div class="card-body">
            <div v-if="spinner" class="text-center">
              <b-spinner variant="success" label="Spinning"></b-spinner>
            </div>
            <ListPublic v-if="!spinner" :tickets="tickets" routeName="TicketDetailsUser"></ListPublic>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ListPublic from '@/components/ListPublic.vue'
import BackIcon from '@/components/BackIcon.vue'

export default {
  name: 'TicketsUser',

  data() {
    return {
      spinner: false,
      tickets: [],
      status: '',
      totalTickets: '',
      label: 'Tickets'
    }
  },

  watch: {
    '$route.params.status': function(status) {
      this.loadTickets(status)
      this.status = status
      window.scrollTo(0, 0)
    }
  },

  mounted() {
    let status = 'all'
    if (this.$route.params.status != undefined) {
      status = this.$route.params.status
    }
    this.loadTickets(status)
    this.status = status
  },

  methods: {
    loadTickets(status) {
      this.spinner = true
      axios.get('/user/tickets?status=' + status).then(res => {
        if (res.data.status == 'success') {
          this.tickets = res.data.tickets
          this.totalTickets = res.data.totalTickets
          this.label = res.data.label
        } else {
          //this.$swal("Ooops!", res.data.message, "error");
          this.tickets = []
        }
        this.spinner = false
      })
    }
  },

  components: {
    ListPublic,
    // ChatAuthor,
    BackIcon
  }
}
</script>

<style></style>
