<template>
  <span id="ChangeLogModal" style="display: inline">
    <a href="#" v-b-modal="modalID" class="btn btn-outline-info btn-sm ml-1 mr-1" title="Change Log">History</a>

    <!-- arrow-right -->
    <b-modal :id="modalID" :title="`#${ticket.ref} history`" size="lg" hide-footer no-close-on-backdrop @shown="modalShown">
      <div id="history">
        <table class="table table-hover table-bordered table-sm" style="font-size: .8rem" v-if="!spinner">
          <thead class="thead-light">
            <tr>
              <th>Date/Time</th>
              <th>Action</th>
              <th>By</th>
              <!-- <th>Comment</th> -->
              <th>AssignedTo</th>
              <th>EscalatedTo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(a, i) in logs" :key="i">
              <td>{{ a.updatedAt | formatDateTime }}</td>
              <td>
                <span v-if="a.ticketStatus.name !== ''"> {{ a.ticketStatus.name }}</span>
                <span v-else>{{ a.comment }}</span>
              </td>
              <td>{{ a.user.name }}</td>
              <!-- <td>
                <span v-if="a.comment == ''">N/A </span>
                <span v-else>{{ a.comment }}</span>
              </td>
 -->
              <td>
                <span v-if="a.assignedToUser.name == ''">N/A </span>
                <span v-else>
                  {{ a.assignedToUser.name }}
                  <small>{{ a.assignedToUser.email }}</small>
                </span>
              </td>

              <td>
                <span v-if="a.escalatedToUser.name == ''">N/A </span>
                <span v-else
                  >{{ a.escalatedToUser.name }} <small>{{ a.escalatedToUser.email }}</small>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
      </div>
    </b-modal>
  </span>
</template>

<script>
import axios from 'axios'

export default {
  name: 'TicketHistoryModal',

  data() {
    return {
      spinner: false,
      modalID: 'TicketHistoryModal',
      priority: '',
      logs: []
    }
  },

  props: {
    ticket: { type: Object }
  },

  methods: {
    modalShown(e) {
      this.spinner = true
      axios.get(`/ticket-history?ticketID=${this.ticket.ref}`).then(res => {
        if (res.data.status == 'success') {
          this.logs = res.data.history
        } else {
          this.$swal('Ooops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    }
  },

  computed: {
    /* client() {
      return this.$store.state.client;
    }, */
  }
}
</script>

<style scoped>
#history {
  width: 100%;
  max-height: 600px;
  overflow-y: auto;
}
</style>
