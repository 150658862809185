var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid",attrs:{"id":"TicketsLog"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header"},[_vm._v("TicketsLog ("+_vm._s(_vm.reports.length)+")")]),_c('div',{staticClass:"card-body"},[(_vm.spinner)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"warning","label":"Spinning"}})],1):_vm._e(),(!_vm.spinner)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.reports,"line-numbers":true,"pagination-options":{
              enabled: true,
              perPage: 50
            },"search-options":{
              enabled: true,
              trigger: 'enter'
            },"sort-options":{
              enabled: true,
              initialSortBy: [{ field: 'Date', type: 'desc' }]
            },"styleClass":"vgt-table condensed myGoodTable"}},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticClass:"input-group mb-2 mr-sm-2"},[_c('label',{staticClass:"mt-2 ml-5"},[_vm._v("Date Range:")]),_c('RangedatePicker',{staticClass:"mr-5",attrs:{"i18n":"EN","righttoleft":"true"},on:{"selected":_vm.selectedDateRange}}),_c('a',{staticClass:"btn btn-sm btn-secondary ml-4",attrs:{"href":_vm.downloadLink}},[_vm._v("Download")])],1)])]):_vm._e()],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }