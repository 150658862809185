<template>
  <div id="CustomRoleDetailsAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">{{ customRole.name }}</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header"><BackIcon /> {{ customRole.name }}</div>
          <div class="card-body-">
            <vue-good-table
              :columns="columns"
              :rows="customRoleItems"
              :line-numbers="true"
              :search-options="{
                enabled: true,
                initialSortBy: [{ field: 'name', type: 'asc' }]
              }"
              :pagination-options="{
                enabled: true,
                perPage: 20
              }"
              styleClass="vgt-table condensed myGoodTable"
            >
              <div slot="table-actions">
                <CreateSubItem />
              </div>
              <!--  -->
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'name'">{{ props.row.name }} </span>
                <span v-if="props.column.field == 'totalUsers'">{{ props.row.totalUsers }}</span>
                <span v-if="props.column.field == 'createdAt'">{{ props.row.createdAt }}</span>
                <span v-if="props.column.field == 'users' && authUserRole == 'admin'">
                  <AssignUserToSubItem :customRoleItem="props.row"></AssignUserToSubItem>
                </span>

                <span v-if="props.column.field == 'edit' && authUserRole == 'admin'">
                  <EditSubItem :customRoleItem="props.row" />
                </span>

                <span v-if="props.column.field == 'delete' && authUserRole == 'admin'">
                  <button class="btn btn-xs btn-outline-secondary" title="Delete Item" @click="deleteSubItem(props.row.uuid, props.index)">Delete</button>
                </span>
              </template>
              <!--  -->
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BackIcon from '@/components/BackIcon.vue'
import { required, minLength } from 'vuelidate/lib/validators'
//import AssignUserToCustomRole from './components//AssignUserToCustomRole'
import AssignUserToSubItem from './components/subItemModals/AssignUserToSubItem'
import EditSubItem from './components/subItemModals/EditSubItem'
import CreateSubItem from './components/subItemModals/CreateSubItem'
//import SubItems from "./components/SubItems";

export default {
  name: 'CustomRoleDetailsAdmin',

  data() {
    return {
      customRole: {},
      customRoleItems: [],
      users: []
    }
  },

  components: {
    //AssignUserToCustomRole,
    //SubItems,
    AssignUserToSubItem,
    CreateSubItem,
    EditSubItem,
    BackIcon
  },

  mounted() {
    let loading = this.$loading.show()
    axios.get(`/${this.authUserRole}/custom-role/${this.$route.params.uuid}`).then(res => {
      this.users = res.data.users
      //console.log(res.data.users)

      this.customRole = res.data.customRole
      this.customRoleItems = res.data.customRoleItems

      // console.log("customRole", this.customRole);
      //console.log("items", this.customRoleItems);

      loading.hide()
    })
    axios.get(`/${this.authUserRole}/users?role=custom`).then(res => {
      this.users = res.data.users
      // console.log("users", this.users);
      loading.hide()
    })
  },

  methods: {
    createCustomRoleItem() {
      let loading = this.$loading.show()
      this.$v.$touch()
      if (this.$v.$invalid) {
        loading.hide()
        return
      }
      let formData = new FormData()
      formData.append('name', this.itemFormName)
      formData.append('customRoleUUID', this.customRole.uuid)
      axios.post('admin/custom-role-item/create', formData).then(res => {
        if (res.data.status == 'success') {
          //console.log(res.data.customRoleItem)
          this.customRoleItems.push(res.data.customRoleItem)
          this.$swal('', `${this.itemFormName} created successfully`, 'success')
          this.itemFormName = ''
          this.$v.$reset()
        } else {
          this.$swal('', res.data.message, 'error')
        }
        loading.hide()
      })
    },

    deleteSubItem(uuid, index) {
      //console.log(uuid, index)
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'DELETE: ' + this.customRoleItems[index].name,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        })
        .then(result => {
          if (result.value) {
            /* actural action */
            let loading = this.$loading.show()

            axios.post(`/admin/custom-role-item/delete/${uuid}`).then(res => {
              if (res.data.status == 'success') {
                this.$swal.fire('Deleted!', `${this.customRoleItems[index].name} deleted`, 'success')
                this.customRoleItems.splice(index, 1)
              } else {
                this.$swal('', res.data.message, 'error')
              }
              loading.hide()
            })
            /* end of actural action */
          }
        })
    }
  },

  validations: {
    itemFormName: { required, minLength: minLength(2) }
  },

  computed: {
    client() {
      return this.$store.state.client
    },

    columns() {
      let cols = [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Users Assigned',
          field: 'totalUsers',
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Date/Time',
          field: 'createdAt'
        },
        {
          label: '',
          field: 'users',
          sortable: false
        },
        {
          label: '',
          field: 'edit',
          sortable: false
        },
        {
          label: '',
          field: 'delete',
          sortable: false
        }
      ]
      return cols
    },

    authUserRole() {
      return this.$store.state.authUser.Role
    }
  }
}
</script>
